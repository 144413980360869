/*
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../components/Header";
import GaleriaProductos from "./GaleriaProductos";
import DetallesProducto from "./DetallesProducto";
import CarritoPage from "./Carrito";
import Error from "./Error";
import Footer from "../components/Footer";
import MisCompras from "./MisCompras";

import { ProductsProvider } from "../contextos/ProductsContext"

function Home() {

  return (
    <>
      <ProductsProvider>
        <Header />
      </ProductsProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<GaleriaProductos />} />
          <Route path="detalle" element={<DetallesProducto />} />
          <Route path="carrito" element={<CarritoPage />} />
          <Route path="miscompras" element={<MisCompras />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default Home;
*/



import React from "react";
import { Routes, Route } from "react-router-dom";

import GaleriaProductos from "./GaleriaProductos";
import DetallesProducto from "./DetallesProducto";
import CarritoPage from "./Carrito";
import Error from "./Error";
import MisCompras from "./MisCompras";

import { ProductsProvider } from "../contextos/ProductsContext";

function Home() {
  return (
    <ProductsProvider>
      <Routes>
        <Route path="/" element={<GaleriaProductos />} />
        {/*
        <Route path="/detalle" element={<DetallesProducto />} />
         */}
        <Route path="/productos" element={<DetallesProducto />} />
        <Route path="/carrito" element={<CarritoPage />} />
        <Route path="/miscompras" element={<MisCompras />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </ProductsProvider>
  );
}

export default Home;
