/*
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faUser,
  faCaretDown,
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';
import { ProductsContext } from "../contextos/ProductsContext";
import { AuthContext } from "../contextos/AuthContext";
import { useNavigate } from "react-router-dom";

function Header() {
  const { cart } = useContext(CartContext);
  const { menuMotos } = useContext(ProductsContext);
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);
  const [cartCant, setCartCant] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let counter = 0;
    Object.values(cart).forEach((prod) => {
      counter += prod.cant;
    });
    setCartCant(counter);
  }, [cart]);

  const handleTriggerClick = () => {
    setMenuActive(!menuActive);
  };

  const handleMenuClick = () => {
    const mainMenu = document.getElementById("main_menu");
    mainMenu.classList.toggle("active");
  };

  const formatear = () => {
    let menu = document.querySelector('span#hs_cos_wrapper_my_menu');
    let menuModelos = document.getElementById('main_menu');
    let headerHeight = document.querySelector('header').offsetHeight;
    let menuItems = document.querySelectorAll('ul[role="menu"] li');
    let burgerMenu = document.getElementById("burger-menu");
    let mainMenuColumns = document.querySelectorAll('.main_menu_column');
    let columnTitles = document.querySelectorAll('.column_title');
    let columnModels = document.querySelectorAll('.models_column');
    const columnCategoryTitles = document.getElementsByClassName('column_title');
    const columnCount = columnCategoryTitles.length;
    const titleWidth = 100 / columnCount;
    const mainMenu = document.querySelector("#main_menu");
    const backButton = document.querySelector("#back_btn");

    function menuInitialHeight() {
      menuModelos.style.top = headerHeight + 'px';
    }
    menuInitialHeight();

    function toggleMenu() {
      menu.classList.toggle("active");
    }
    burgerMenu.addEventListener("click", function () {
      toggleMenu();
    });

    function activateMenu(trigerMenu) {
      trigerMenu.addEventListener('click', function () {
        menuModelos.classList.toggle("active");
        calcularCantidadModelEnAnchoPantalla();
      });
    }

    function backModelsMenu() {
      mainMenu.classList.remove("active");
    }

    function modelFade() {
      let columActive = document.querySelectorAll(".main_menu_column.active .model");
      let delay = 0.2;
      columActive.forEach(function (element, index) {
        element.classList.add('fadeUp');
        let elementDelay = (index + 1) * delay;
        element.style.animationDelay = elementDelay + 's';
      });
    }

    function activateColumn(columnTitles, thisTitle) {
      columnTitles.forEach(columnTitle => {
        let parentColumn = thisTitle.parentNode;
        mainMenuColumns.forEach(menuColumn => {
          menuColumn.classList.remove('active');
        });
        parentColumn.classList.add('active');
      });
      modelFade();
    }

    function calcularCantidadModelEnAnchoPantalla() {
      var modelWidth = document.querySelector('.model').offsetWidth;
      var screenWidth = window.innerWidth;
      var quantity = Math.floor(screenWidth / modelWidth);
      var containers = document.querySelectorAll('.models_column');
      var containerWithMostItems;
      var maxItemCount = 0;
      containers.forEach(function (container) {
        var itemCount = container.querySelectorAll('.model').length;
        if (itemCount > maxItemCount) {
          maxItemCount = itemCount;
          containerWithMostItems = container;
        }
      });
      if (maxItemCount > quantity) {
        document.querySelector('#main_menu').classList.add('horizontal_flex');
      }
    }

    for (let i = 0; i < menuItems.length; i++) {
      let menuItem = menuItems[i];
      if (menuItem.textContent === "{{ module.id_menu }}") {
        menuItem.classList.add("triger_menu");
        let trigerMenu = document.querySelector(".triger_menu");
        activateMenu(trigerMenu);
        modelFade();
        break;
      }
    }

    for (let i = 0; i < columnCount; i++) {
      columnCategoryTitles[i].style.width = `${titleWidth}%`;
      columnCategoryTitles[i].style.left = `${titleWidth * i}%`;
    }

    columnTitles.forEach(columnTitle => {
      columnTitle.addEventListener('click', () => {
        activateColumn(columnTitles, columnTitle);
      });
    });

    backButton.addEventListener("click", function () {
      backModelsMenu();
    });

    function handleMenuActivation_() {
      var mainMenu_ = document.querySelector('#main_menu.active');
      var menuColumns_ = document.querySelectorAll('.main_menu_column');
      menuColumns_.forEach(function (column_) {
        column_.classList.remove('next', 'prev');
      });
      if (mainMenu_) {
        var activeColumn_ = mainMenu_.querySelector('.main_menu_column.active');
        if (activeColumn_) {
          var columns_ = Array.from(activeColumn_.parentNode.children);
          var activeIndex_ = columns_.indexOf(activeColumn_);
          if (activeIndex_ < columns_.length - 1) {
            var nextColumn_ = columns_[activeIndex_ + 1];
            nextColumn_.classList.add('next');
          }
          if (activeIndex_ > 0) {
            var prevColumn_ = columns_[activeIndex_ - 1];
            prevColumn_.classList.add('prev');
          }
        }
      }
    }

    var menuColumns_ = document.querySelectorAll('.main_menu_column');
    menuColumns_.forEach(function (column_) {
      column_.addEventListener('click', handleMenuActivation_);
    });

    var mainMenu_ = document.querySelector('#main_menu');
    mainMenu_.addEventListener('click', handleMenuActivation_);
  }

  useEffect(() => {
    setTimeout(() => formatear(), 1000);
  }, []);

  return (
    <header className="header">
      <div className="header_container">
        <div className="cont-header">
          <div className="header_logo">
            <a href="/">
              <img src="./logo-thunder.webp" alt="Thunder Logo" width="160"/>
            </a>
          </div>
          <span id="hs_cos_wrapper_my_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu">
            <div id="hs_menu_wrapper_my_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="Default" data-menu-id="115201409801" aria-label="Navigation Menu">
              <ul role="menu" className="active-branch">
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com" role="menuitem">Inicio</a></li>
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem">Nosotros</a></li>
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem">Agencias</a></li>
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://blog.thundermotos.com" role="menuitem">Blog</a></li>
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/accesorios" role="menuitem">Accesorios</a></li>
                <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/contacto" role="menuitem">Contacto</a></li>
              </ul>
            </div>
          </span>
          <div id="hs_cos_wrapper_main_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module">
            <section className={`main_menu horizontal_flex ${menuActive ? "active" : ""}`} id="main_menu">
              <button className="btn" id="back_btn" type="button" title="volver">
                <span className="back_icon_btn"></span>
              </button>
              {menuMotos ?
                Object.keys(menuMotos).map((categoria, i) => {
                  return (
                    <div className={i === 0 ? "main_menu_column active" : i === 1 ? "main_menu_column next" : "main_menu_column"} data-index={i}>
                      <h4 className="column_title">
                        {categoria}
                      </h4>
                      <div className="models_column" data-index={i}>
                        {Object.keys(menuMotos[categoria]).map(modelo => {
                          return (
                            <div className="model fadeUp" id={menuMotos[categoria][modelo][0].id}>
                              <h4 className="model_title">
                                <a className="model_url" href={menuMotos[categoria][modelo][0].properties.hs_url} title={menuMotos[categoria][modelo][0].properties.name}>{menuMotos[categoria][modelo][0].properties.name}</a>
                              </h4>
                              <span className="model_price">${menuMotos[categoria][modelo][0].properties.precio_tc}</span>
                              <a className="model_url" href={menuMotos[categoria][modelo][0].properties.hs_url} title={menuMotos[categoria][modelo][0].properties.name}>
                                <img className="model_img" src={menuMotos[categoria][modelo][0].properties.hs_images} alt={menuMotos[categoria][modelo][0].properties.name} width="180" height="180" loading="lazy" sizes="(max-width: 180px) 100vw, 180px" />
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
                : null
              }
            </section>
          </div>
          <div id="burger-menu" className="burger-menu" onClick={handleTriggerClick}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
        <div className="header-iconos">
        <div className="carrito">
          {cart && cartCant > 0 ? <div id="carrito-cant">{cartCant}</div> : null}
          <a id="carrito-icon" href="/carrito">
          <FontAwesomeIcon icon={faCartShopping} />
          </a>
        </div>
        <div className="account">
          <a id="account-icon" href="/miscompras">
            <FontAwesomeIcon icon={faUser} />
          </a>
        </div>
        {isLoggedIn && (
        <div className="logout">
            <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogout} />
        </div>
        )}
        </div>
      </div>
    </header>
  );
}

export default Header;
*/


/*
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser, faRightFromBracket, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';
import { AuthContext } from '../contextos/AuthContext';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { cart } = useContext(CartContext);
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  const [cartCant, setCartCant] = useState(0);
  const [menuActive, setMenuActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let counter = 0;
    Object.values(cart).forEach((prod) => {
      counter += prod.cant;
    });
    setCartCant(counter);
  }, [cart]);

  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header className="header">
      <div className="header_container">
        <div className="cont-header">
          <a href="/">
            <img src="./logo-thunder.webp" alt="Thunder Logo" width="160" />
          </a>
          <nav id="main-menu" className={`hs-menu-wrapper ${menuActive ? 'active' : ''}`}>
            <ul role="menu" className="main_menu">
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com" role="menuitem">Inicio</a></li>
              <li className="menu-item" role="none"><a href="/" role="menuitem">Tienda</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem">Nosotros</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem">Agencias</a></li>
              <li className="menu-item" role="none"><a href="https://blog.thundermotos.com" role="menuitem">Blog</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/accesorios" role="menuitem">Accesorios</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/contacto" role="menuitem">Contacto</a></li>
            </ul>
          </nav>
          <div className="menu-toggle" onClick={handleMenuToggle}>
            <FontAwesomeIcon icon={menuActive ? faTimes : faBars} />
          </div>
        </div>
        <nav className="header-iconos">
          <ul>
            <li className="carrito">
              {cart && cartCant > 0 ? <div id="carrito-cant">{cartCant}</div> : null}
              <a id="carrito-icon" href="/carrito">
                <FontAwesomeIcon icon={faCartShopping} />
              </a>
            </li>
            <li className="account">
              <a id="account-icon" href="/miscompras">
                <FontAwesomeIcon icon={faUser} />
              </a>
            </li>
            {isLoggedIn && (
              <li className="logout">
                <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogout} />
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
*/

import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser, faRightFromBracket, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';
import { AuthContext } from '../contextos/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Header() {
  const { cart } = useContext(CartContext);
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  const [cartCant, setCartCant] = useState(0);
  const [menuActive, setMenuActive] = useState(false);
  const [activeBranch, setActiveBranch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let counter = 0;
    Object.values(cart).forEach((prod) => {
      counter += prod.cant;
    });
    setCartCant(counter);
  }, [cart]);

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveBranch(true);
    } else {
      setActiveBranch(false);
    }
  }, [location]);

  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header className="header">
      <div className="header_container">
        <div className="cont-header">
          <a href="/">
            <img src="./logo-thunder.webp" alt="Thunder Logo" width="160" />
          </a>
          <nav id="main-menu" className={`hs-menu-wrapper ${menuActive ? 'active' : ''}`}>
            <ul role="menu" className="main_menu">
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com" role="menuitem">Inicio</a></li>
              <li className={`menu-item ${activeBranch ? 'active-branch' : ''}`} role="none"><a href="/" role="menuitem">Tienda</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem">Nosotros</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem">Agencias</a></li>
              <li className="menu-item" role="none"><a href="https://blog.thundermotos.com" role="menuitem">Blog</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/accesorios" role="menuitem">Accesorios</a></li>
              <li className="menu-item" role="none"><a href="https://www.thundermotos.com/contacto" role="menuitem">Contacto</a></li>
            </ul>
          </nav>
        </div>
        <nav className="header-iconos">
          <ul>
            <li className="carrito">
              {cart && cartCant > 0 ? <div id="carrito-cant">{cartCant}</div> : null}
              <a id="carrito-icon" href="/carrito">
                <FontAwesomeIcon icon={faCartShopping} />
              </a>
            </li>
            <li className="account">
              <a id="account-icon" href="/miscompras">
                <FontAwesomeIcon icon={faUser} />
              </a>
            </li>
            {isLoggedIn && (
              <li className="logout">
                <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogout} />
              </li>
            )}
          </ul>
        </nav>
        <div className="menu-toggle" onClick={handleMenuToggle}>
          <FontAwesomeIcon icon={menuActive ? faTimes : faBars} />
        </div>
      </div>
      <ToastContainer />
    </header>
  );
}

export default Header;
