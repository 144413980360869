/*
import React from "react";

function Compra({ id, props, paymentStatus, paymentAuthorization }) {
  const purchaseInfo = props.resumencompra ? JSON.parse(props.resumencompra) : {};
  const fecha = props.createdate ? new Date(props.createdate) : new Date();
  
  const { purchaseCart = {}, purchaseShippingInfo = {}, purchaseTotal = 0, purchaseDate = fecha } = purchaseInfo;
  const formattedDate = new Date(purchaseDate).toLocaleDateString();
  const formattedTime = new Date(purchaseDate).toLocaleTimeString();
  
  const isPendingPayment = paymentStatus === "PENDING";
  const paymentUrl = props.placetopay_url;

  console.log(props.requestid, purchaseInfo);
  console.log("Propiedades de la compra", paymentAuthorization);
  //console.log(purchaseShippingInfo, purchaseCart);

  return (
    <div className="compra">
      <p className="fecha"><strong>REF:</strong> {props.placetopay_reference} - Realizada el dia: {formattedDate} a las {formattedTime}</p>
      <ul>
        {Object.keys(purchaseCart).length > 0 ? (
          Object.values(purchaseCart).map((product, index) => (
            <li key={index}>
              {product.name} x {product.cant} / PRECIO: {parseFloat(product.price).toFixed(2)} / TOTAL: {(parseFloat(product.price) * product.cant).toFixed(2)}
            </li>
          ))
        ) : (
          <li>No hay productos</li>
        )}
        {purchaseShippingInfo.shippingCost > 0 && (
          <li>Valor del envío: ${parseFloat(purchaseShippingInfo.shippingCost).toFixed(2)}</li>
        )}
      </ul>
      <p className="total-compra"><strong>Total:</strong> ${parseFloat(purchaseTotal).toFixed(2)}</p>
      <div className={paymentStatus}>
        <p className="estado-pago"><strong>Estado del Pago:</strong> <span>{paymentStatus === "REJECTED" ? "RECHAZADO" : paymentStatus === "PENDING" ? "PENDIENTE" : "APROBADO"}</span></p>
        {paymentAuthorization && paymentAuthorization.authorization && (
          <p><strong>Código de autorización:</strong> {paymentAuthorization.authorization}</p>
        )}
      </div>
      {isPendingPayment && paymentUrl && (
        <div className="pago-pendiente">
          <p>Tu pago está pendiente. Puedes retomarlo haciendo clic en el botón de abajo:</p>
          <a href={paymentUrl} className="boton-pago" target="_blank" rel="noopener noreferrer">
            Continuar Pago
          </a>
        </div>
      )}
    </div>
  );
}

export default Compra;
*/








/**************/
/**************/
/**************/
/**************/
/**************/
/**************/
/**************/
/**************/
/**************/

/*
import React from "react";

function Compra({ id, props, paymentStatus, paymentAuthorization }) {
  const purchaseInfo = props.resumencompra ? JSON.parse(props.resumencompra) : {};
  const fecha = props.createdate ? new Date(props.createdate) : new Date();

  const { purchaseCart = {}, purchaseShippingInfo = {}, purchaseTotal = 0, purchaseDate = fecha } = purchaseInfo;
  const formattedDate = new Date(purchaseDate).toLocaleDateString();
  const formattedTime = new Date(purchaseDate).toLocaleTimeString();

  const isPendingPayment = paymentStatus === "PENDING";
  const paymentUrl = props.placetopay_url;

  // Verificar si authorization está llegando correctamente
  console.log("Propiedades de la compra", paymentAuthorization);

  return (
    <div className="compra">
      <p className="fecha"><strong>REF:</strong> {props.placetopay_reference} - Realizada el dia: {formattedDate} a las {formattedTime}</p>
      <ul>
        {Object.keys(purchaseCart).length > 0 ? (
          Object.values(purchaseCart).map((product, index) => (
            <li key={index}>
              {product.name} x {product.cant} / PRECIO: {parseFloat(product.price).toFixed(2)} / TOTAL: {(parseFloat(product.price) * product.cant).toFixed(2)}
            </li>
          ))
        ) : (
          <li>No hay productos</li>
        )}
        {purchaseShippingInfo.shippingCost > 0 && (
          <li>Valor del envío: ${parseFloat(purchaseShippingInfo.shippingCost).toFixed(2)}</li>
        )}
      </ul>
      <p className="total-compra"><strong>Total:</strong> ${parseFloat(purchaseTotal).toFixed(2)}</p>
      <div className={paymentStatus}>
      <p className="estado-pago"><strong>Estado del Pago:</strong> <span className={paymentStatus}>{paymentStatus === "REJECTED" ? "RECHAZADO" : paymentStatus === "PENDING" ? "PENDIENTE" : paymentStatus === "APPROVED" ? "APROBADO" : "ERROR"}</span></p>
        {paymentAuthorization && paymentAuthorization.authorization && (
          <p><strong>Código de autorización:</strong> {paymentAuthorization.authorization}</p>
        )}
      </div>
      {isPendingPayment && paymentUrl && (
        <div className="pago-pendiente">
          <p>Tu pago está pendiente. Puedes retomarlo haciendo clic en el botón de abajo:</p>
          <a href={paymentUrl} className="boton-pago" target="_blank" rel="noopener noreferrer">
            Continuar Pago
          </a>
        </div>
      )}
    </div>
  );
}

export default Compra;
*/

import React from "react";

function Compra({ id, props, paymentStatus, paymentAuthorization }) {
  // Manejar el análisis de JSON de forma segura
  let purchaseInfo = {};
  try {
    purchaseInfo = props.resumencompra ? JSON.parse(props.resumencompra) : {};
  } catch (error) {
    console.error("Error parsing resumencompra:", error);
    purchaseInfo = {}; // Si hay un error, establecer un valor por defecto
  }

  const fecha = props.createdate ? new Date(props.createdate) : new Date();

  const { purchaseCart = {}, purchaseShippingInfo = {}, purchaseTotal = 0, purchaseDate = fecha } = purchaseInfo;
  const formattedDate = new Date(purchaseDate).toLocaleDateString();
  const formattedTime = new Date(purchaseDate).toLocaleTimeString();

  const isPendingPayment = paymentStatus === "PENDING";
  const paymentUrl = props.placetopay_url;

  // Verificar si authorization está llegando correctamente
  console.log("Propiedades de la compra", paymentAuthorization);

  return (
    <div className="compra">
      <p className="fecha"><strong>REF:</strong> {props.placetopay_reference} - Realizada el dia: {formattedDate} a las {formattedTime}</p>
      <ul>
        {Object.keys(purchaseCart).length > 0 ? (
          Object.values(purchaseCart).map((product, index) => (
            <li key={index}>
              {product.name} x {product.cant} / PRECIO: {parseFloat(product.price).toFixed(2)} / TOTAL: {(parseFloat(product.price) * product.cant).toFixed(2)}
            </li>
          ))
        ) : (
          <li>No hay productos</li>
        )}
        {purchaseShippingInfo.shippingCost > 0 && (
          <li>Valor del envío: ${parseFloat(purchaseShippingInfo.shippingCost).toFixed(2)}</li>
        )}
      </ul>
      <p className="total-compra"><strong>Total:</strong> ${parseFloat(purchaseTotal).toFixed(2)}</p>
      <div className={paymentStatus}>
      <p className="estado-pago"><strong>Estado del Pago:</strong> <span className={paymentStatus}>{paymentStatus === "REJECTED" ? "RECHAZADO" : paymentStatus === "PENDING" ? "PENDIENTE" : paymentStatus === "APPROVED" ? "APROBADO" : "ERROR"}</span></p>
        {paymentAuthorization && paymentAuthorization.authorization && (
          <p><strong>Código de autorización:</strong> {paymentAuthorization.authorization}</p>
        )}
      </div>
      {isPendingPayment && paymentUrl && (
        <div className="pago-pendiente">
          <p>Tu pago está pendiente. Puedes retomarlo haciendo clic en el botón de abajo:</p>
          <a href={paymentUrl} className="boton-pago" target="_blank" rel="noopener noreferrer">
            Continuar Pago
          </a>
        </div>
      )}
    </div>
  );
}

export default Compra;