/******************************************************************************
 * Versión Galerias separada de logica de filtros */
/******************************************************************************/
/*
import React, { useState, useEffect, useContext } from 'react';
import Producto from './Producto';
import ProductoPlaceholder from './ProductoPlaceholder'; // Componente placeholder
import { ProductsContext } from "../contextos/ProductsContext";
import { useNavigate } from "react-router-dom";

function Galerias() {
  const { productos } = useContext(ProductsContext);
  const [motosFilteredProducts, setMotosFilteredProducts] = useState([]);
  const [accesoriosFilteredProducts, setAccesoriosFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga

  const [loadingFilteredProducts, setLoadingFilteredProducts] = useState(false); // Estado para mostrar el mensaje de "No se encontraron productos"

  const [filters, setFilters] = useState({
    motos: {
      tipoSubFamiliaSelect: "",
      tipoModelosSelect: "",
      colorSelect: "",
    },
    accesorios: {
      familiaSelect: "",
      colorSelect: "",
      tallaSelect: "",
    }
  });

  const [modelosMotos, setModelosMotos] = useState([]);
  const [subFamiliasMotos, setSubFamiliasMotos] = useState([]);
  const [coloresAccesorios, setColoresAccesorios] = useState([]);
  const [coloresMotos, setColoresMotos] = useState([]);
  const [tallasAccesorios, setTallasAccesorios] = useState([]);
  const [familiasAccesorios, setFamiliasAccesorios] = useState([]);

  const navigate = useNavigate();

  // Función para aplicar los filtros
  const applyFilters = (motos, accesorios) => {
    const filteredMotos = filterProducts(motos, filters.motos, 'motos');
    const filteredAccesorios = filterProducts(accesorios, filters.accesorios, 'accesorios');

    setMotosFilteredProducts(filteredMotos);
    setAccesoriosFilteredProducts(filteredAccesorios);

    // Extraer modelos de motos
    const motoModelSet = new Set(filteredMotos.map((modelo) => modelo.modelo));
    setModelosMotos([...motoModelSet]);
  };

  // Filtrar productos según los filtros seleccionados
  const filterProducts = (productos, filtros, tipo) => {
    let filteredProducts = productos;

    if (tipo === 'motos') {
      if (filtros.tipoSubFamiliaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_sub_familia === filtros.tipoSubFamiliaSelect)
        );
      }
      if (filtros.tipoModelosSelect) {
        filteredProducts = filteredProducts.filter(modelo => modelo.modelo === filtros.tipoModelosSelect);
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.color1 === filtros.colorSelect || producto.color2 === filtros.colorSelect)
        );
      }
    } else if (tipo === 'accesorios') {
      if (filtros.familiaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_familia === filtros.familiaSelect)
        );
        extractAccesorioColoresYtallas(filteredProducts);
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto =>
            producto.color1 === filtros.colorSelect ||
            producto.color2 === filtros.colorSelect ||
            (filtros.colorSelect === 'Otros colores' && (producto.color1 === 'Sin Color' || producto.color2 === 'Sin Color'))
          )
        );
      }
      if (filtros.tallaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => {
            const talla = extractTalla(producto.name);
            return talla === filtros.tallaSelect;
          })
        );
      }
    }

    return filteredProducts;
  };

  // useEffect para aplicar filtros y obtener datos iniciales
  useEffect(() => {
    setIsLoading(true); // Activar el estado de carga
    const motos = [];
    const accesorios = [];
  
    // Clasificar productos en motos o accesorios
    Object.keys(productos).forEach((modelo) => {
      const modeloInfo = productos[modelo];
      const { productos: productList } = modeloInfo;
  
      const categoria = productList[0]?.categoria;
      if (categoria === "1009 MOTOS") {
        motos.push({ ...modeloInfo, modelo });
      } else if (categoria === "002 ACCESORIOS") {
        const modeloToUse = modelo === "Sin Modelo" ? productList[0]?.descripcion_familia : modelo;
        accesorios.push({ ...modeloInfo, modelo: modeloToUse });
      }
    });
  
    // Aplicar filtros (si hay) y obtener colores y tallas incluso sin categorías
    applyFilters(motos, accesorios);
    extractMotoSubFamilias(motos); // Extraer subfamilias de motos
    extractFamiliasAccesorios(accesorios); // Extraer familias de accesorios
    extractMotoColores(motos); // Extraer colores de motos
    extractAccesorioColoresYtallas(accesorios); // Extraer colores y tallas de accesorios
  
    setIsLoading(false); // Desactivar el estado de carga cuando los productos se hayan filtrado

  }, [productos, filters]); // Ahora también extraemos los colores y tallas siempre
  
  
  // Extraer subfamilias, colores y tallas de productos
  const extractMotoSubFamilias = (motos) => {
    const subFamiliaSet = new Set(
      motos.flatMap((modelo) =>
        modelo.productos.map((producto) => producto.descripcion_sub_familia).filter(Boolean)
      )
    );
    setSubFamiliasMotos([...subFamiliaSet]);
  };

  const extractFamiliasAccesorios = (accesorios) => {
    const familiaSet = new Set(
      accesorios.flatMap((modelo) =>
        modelo.productos.map((producto) => producto.descripcion_familia).filter(Boolean)
      )
    );
    setFamiliasAccesorios([...familiaSet]);
  };

  const extractAccesorioColoresYtallas = (accesorios) => {
    const colorSet = new Set();
    const tallaSet = new Set();
    accesorios.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1 && producto.color1 !== "Sin Color") colorSet.add(producto.color1);
        if (producto.color2 && producto.color2 !== "Sin Color") colorSet.add(producto.color2);
        const talla = extractTalla(producto.name);
        if (talla) tallaSet.add(talla);
      });
    });
    colorSet.add("Otros colores"); // Asegurar que 'Otros colores' esté disponible
    setColoresAccesorios([...colorSet]); // Mostrar siempre los colores al cargar
    setTallasAccesorios([...tallaSet]); // Mostrar siempre las tallas al cargar
  };

  const extractMotoColores = (motos) => {
    const colorSet = new Set();
    motos.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1 && producto.color1 !== "Sin Color") colorSet.add(producto.color1);
        if (producto.color2 && producto.color2 !== "Sin Color") colorSet.add(producto.color2);
      });
    });
    setColoresMotos([...colorSet]);
  };

  const extractTalla = (name) => {
    const match = name.match(/TALLA\s(\w+)/i);
    return match ? match[1] : null;
  };

  // Manejar cambios en los filtros
  const handleFilterChange = (e, category, filter) => {
    
    setLoadingFilteredProducts(true); // Mostrar mensaje de "No se encontraron productos"

    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [filter]: e.target.value
      }
    });
  };

  // Navegar a la página de detalles del producto
  const handleClick = (categoria, modelo, descripcion, claveBusqueda) => {
    const base = categoria === "1009 MOTOS" ? "/productos?modelo=" : "/productos?accesorio=";
    const query = encodeURIComponent(claveBusqueda.trim());
    const url = base + query;
    navigate(url);
  };

  // Función auxiliar para renderizar productos o placeholders
  const renderProductsOrPlaceholders = (isLoading, filteredProducts, loadingFilteredProducts) => {
    if (isLoading) {
      // Mostrar placeholders mientras se está cargando
      return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
    }
  
    if (filteredProducts.length > 0) {
      // Si hay productos, renderizarlos
      return filteredProducts.map((modelo) => (
        <div key={modelo.modelo} className="item_product">
          <Producto
            img={modelo.productos[0].hs_images}
            nombre={modelo.modelo}
            precio={modelo.precioMinimo}
            tipo={modelo.productos[0].descripcion_sub_familia || modelo.productos[0].descripcion_familia || "Sin tipo"}
            stock={modelo.stockTotal || "0"}
            isAvailable={parseFloat(modelo.stockTotal) > 0}
            handleClick={() =>
              handleClick(modelo.productos[0].categoria, modelo.productos[0].modelo, modelo.productos[0].descripcion, modelo.claveBusqueda)
            }
          />
        </div>
      ));
    } else {
      // Si no hay productos, verifica si el usuario aplicó un filtro
      if (loadingFilteredProducts) {
        // Si aplicó un filtro y no hay resultados, mostrar mensaje
        return <p>No se encontraron productos para los filtros seleccionados.</p>;
      } else {
        // Si no aplicó filtros, seguir mostrando placeholders
        return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
      }
    }
  };

  return (
    <div className="galerias">
      <h3 className="cat-titulo">MOTOS</h3>
      <div className="filter_container select-filter">
        <select onChange={(e) => handleFilterChange(e, 'motos', 'tipoSubFamiliaSelect')} value={filters.motos.tipoSubFamiliaSelect}>
          <option value="">Todas las categorías</option>
          {subFamiliasMotos.map((subFamilia) => (
            <option key={subFamilia} value={subFamilia}>{subFamilia}</option>
          ))}
        </select>

        <select onChange={(e) => handleFilterChange(e, 'motos', 'tipoModelosSelect')} value={filters.motos.tipoModelosSelect}>
          <option value="">Todos los modelos</option>
          {modelosMotos.map((modelo) => (
            <option key={modelo} value={modelo}>{modelo}</option>
          ))}
        </select>

        <select onChange={(e) => handleFilterChange(e, 'motos', 'colorSelect')} value={filters.motos.colorSelect}>
          <option value="">Todos los colores</option>
          {coloresMotos.map((color) => (
            <option key={color} value={color}>{color}</option>
          ))}
        </select>
      </div>

      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, motosFilteredProducts, loadingFilteredProducts)}
      </div>

      <h3 className="cat-titulo">ACCESORIOS</h3>
      <div className="filter_container select-filter">
        <select onChange={(e) => handleFilterChange(e, 'accesorios', 'familiaSelect')} value={filters.accesorios.familiaSelect}>
          <option value="">Todas las categorías</option>
          {familiasAccesorios.map((familia) => (
            <option key={familia} value={familia}>{familia}</option>
          ))}
        </select>

        <select onChange={(e) => handleFilterChange(e, 'accesorios', 'colorSelect')} value={filters.accesorios.colorSelect}>
          <option value="">Todos los colores</option>
          {coloresAccesorios.map((color) => (
            <option key={color} value={color}>{color === "Sin Color" ? "Otros colores" : color}</option>
          ))}
        </select>

        <select onChange={(e) => handleFilterChange(e, 'accesorios', 'tallaSelect')} value={filters.accesorios.tallaSelect}>
          <option value="">Todas las tallas</option>
          {tallasAccesorios.map((talla) => (
            <option key={talla} value={talla}>{talla}</option>
          ))}
        </select>
      </div>

      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, accesoriosFilteredProducts, loadingFilteredProducts)}
      </div>
    </div>
  );
}

export default Galerias;
*/





/*******************************************/
/*******************************************/
/*******************************************/
/*******************************************/
/*******************************************/
/*******************************************/
/*******************************************/
/*******************************************/

/*

import React, { useState, useEffect, useContext } from 'react';
import Producto from './Producto';
import ProductoPlaceholder from './ProductoPlaceholder';
import { ProductsContext } from "../contextos/ProductsContext";
import { useNavigate } from "react-router-dom";
import FiltrosMotos from './FiltrosMotos';
import FiltrosAccesorios from './FiltrosAccesorios';

function Galerias() {
  const { productos } = useContext(ProductsContext);
  const [motosFilteredProducts, setMotosFilteredProducts] = useState([]);
  const [accesoriosFilteredProducts, setAccesoriosFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFilteredProducts, setLoadingFilteredProducts] = useState(false);

  const [filters, setFilters] = useState({
    motos: {
      tipoSubFamiliaSelect: "",
      tipoModelosSelect: "",
      colorSelect: "",
    },
    accesorios: {
      familiaSelect: "",
      colorSelect: "",
      tallaSelect: "",
    }
  });

  const [modelosMotos, setModelosMotos] = useState([]);
  const [subFamiliasMotos, setSubFamiliasMotos] = useState([]);
  const [coloresAccesorios, setColoresAccesorios] = useState([]);
  const [coloresMotos, setColoresMotos] = useState([]);
  const [tallasAccesorios, setTallasAccesorios] = useState([]);
  const [familiasAccesorios, setFamiliasAccesorios] = useState([]);

  const navigate = useNavigate();

  // Función para aplicar los filtros
  const applyFilters = (motos, accesorios) => {
    const filteredMotos = filterProducts(motos, filters.motos, 'motos');
    const filteredAccesorios = filterProducts(accesorios, filters.accesorios, 'accesorios');

    setMotosFilteredProducts(filteredMotos);
    setAccesoriosFilteredProducts(filteredAccesorios);

    const motoModelSet = new Set(filteredMotos.map((modelo) => modelo.modelo));
    setModelosMotos([...motoModelSet]);

    const colorSetMotos = new Set();
    filteredMotos.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1) colorSetMotos.add(producto.color1);
        if (producto.color2) colorSetMotos.add(producto.color2);
      });
    });
    setColoresMotos([...colorSetMotos]);

    const colorSetAccesorios = new Set();
    const tallaSetAccesorios = new Set();
    filteredAccesorios.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1) colorSetAccesorios.add(producto.color1);
        if (producto.color2) colorSetAccesorios.add(producto.color2);
        if (producto.tallas && producto.tallas !== "Sin Talla") tallaSetAccesorios.add(producto.tallas);
      });
    });
    setColoresAccesorios([...colorSetAccesorios]);
    setTallasAccesorios([...tallaSetAccesorios]);
  };

  // Filtrar productos según los filtros seleccionados
  const filterProducts = (productos, filtros, tipo) => {
    let filteredProducts = productos;

    if (tipo === 'motos') {
      if (filtros.tipoSubFamiliaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_sub_familia === filtros.tipoSubFamiliaSelect)
        );
      }
      if (filtros.tipoModelosSelect) {
        filteredProducts = filteredProducts.filter(modelo => modelo.modelo === filtros.tipoModelosSelect);
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => 
            producto.color1 === filtros.colorSelect || producto.color2 === filtros.colorSelect)
        );
      }
    } else if (tipo === 'accesorios') {
      if (filtros.familiaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_familia === filtros.familiaSelect)
        );
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto =>
            producto.color1 === filtros.colorSelect || producto.color2 === filtros.colorSelect)
        );
      }
      if (filtros.tallaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.tallas === filtros.tallaSelect)
        );
      }
    }

    return filteredProducts;
  };

  // useEffect para aplicar filtros y obtener datos iniciales
  useEffect(() => {
    setIsLoading(true);
    const motos = [];
    const accesorios = [];

    Object.keys(productos).forEach((modelo) => {
      const modeloInfo = productos[modelo];
      const { productos: productList } = modeloInfo;
      const categoria = productList[0]?.categoria;
      if (categoria === "1009 MOTOS") {
        motos.push({ ...modeloInfo, modelo });
      } else if (categoria === "002 ACCESORIOS") {
        const modeloToUse = modelo === "Sin Modelo" ? productList[0]?.descripcion_familia : modelo;
        accesorios.push({ ...modeloInfo, modelo: modeloToUse });
      }
    });

    applyFilters(motos, accesorios);
    extractMotoSubFamilias(motos);
    extractFamiliasAccesorios(accesorios);

    setIsLoading(false);
  }, [productos, filters]);

  const extractMotoSubFamilias = (motos) => {
    const subFamiliaSet = new Set(motos.flatMap((modelo) =>
      modelo.productos?.map((producto) => producto.descripcion_sub_familia).filter(Boolean)
    ));
    setSubFamiliasMotos([...subFamiliaSet]);
  };

  const extractFamiliasAccesorios = (accesorios) => {
    const familiaSet = new Set(accesorios?.flatMap((modelo) =>
      modelo.productos?.map((producto) => producto.descripcion_familia).filter(Boolean)
    ));
    setFamiliasAccesorios([...familiaSet]);
  };

  const handleFilterChange = (e, category, filter) => {
    setLoadingFilteredProducts(true);
    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [filter]: e.target.value
      }
    });
  };

  const handleClick = (categoria, modelo, descripcion, claveBusqueda) => {
    const base = categoria === "1009 MOTOS" ? "/productos?modelo=" : "/productos?accesorio=";
    const query = encodeURIComponent(claveBusqueda.trim());
    const url = base + query;
    navigate(url);
  };

  const handleCategoryChangeMotos = (selectedCategory) => {
    setFilters({
      ...filters,
      motos: {
        categoria: selectedCategory,
        modelo: 'Todos los modelos', // Resetea el filtro de modelos
        color: 'Todos los colores'  // Resetea el filtro de colores
      }
    });
  };
  
  const handleCategoryChangeAccesorios = (selectedCategory) => {
    setFilters({
      ...filters,
      accesorios: {
        categoria: selectedCategory,
        color: 'Todos los colores',  // Resetea el filtro de colores
        talla: 'Todas las tallas'   // Resetea el filtro de tallas
      }
    });
  };

  const renderProductsOrPlaceholders = (isLoading, filteredProducts, loadingFilteredProducts) => {
    if (isLoading) {
      return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
    }

    if (filteredProducts.length > 0) {
      return filteredProducts.map((modelo) => (
        <div key={modelo.modelo} className="item_product">
          <Producto
            img={modelo.productos[0].hs_images}
            nombre={modelo.modelo}
            precio={modelo.precioMinimo}
            tipo={modelo.productos[0].descripcion_sub_familia || modelo.productos[0].descripcion_familia || "Sin tipo"}
            stock={modelo.stockTotal || "0"}
            isAvailable={parseFloat(modelo.stockTotal) > 0}
            handleClick={() =>
              handleClick(modelo.productos[0].categoria, modelo.productos[0].modelo, modelo.productos[0].descripcion, modelo.claveBusqueda)
            }
          />
        </div>
      ));
    } else if (loadingFilteredProducts) {
      return <p>No se encontraron productos para los filtros seleccionados.</p>;
    } else {
      return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
    }
  };

  return (
    <div className="galerias">
      <h3 className="cat-titulo">MOTOS</h3>
      <FiltrosMotos
        filtros={filters.motos}
        subFamiliasMotos={subFamiliasMotos}
        modelosMotos={modelosMotos}
        coloresMotos={coloresMotos}
        setModelosMotos={setModelosMotos}
        setColoresMotos={setColoresMotos}
        setFiltros={(newFilters) => setFilters({ ...filters, motos: newFilters })}
        handleFilterChange={handleFilterChange}
        handleCategoryChangeMotos={handleCategoryChangeMotos}
      />
      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, motosFilteredProducts, loadingFilteredProducts)}
      </div>

      <h3 className="cat-titulo">ACCESORIOS</h3>
      <FiltrosAccesorios
        filtros={filters.accesorios}
        familiasAccesorios={familiasAccesorios}
        coloresAccesorios={coloresAccesorios}
        tallasAccesorios={tallasAccesorios}
        setFiltros={(newFilters) => setFilters({ ...filters, accesorios: newFilters })}
        handleFilterChange={handleFilterChange}
        handleCategoryChangeAccesorios={handleCategoryChangeAccesorios}
      />
      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, accesoriosFilteredProducts, loadingFilteredProducts)}
      </div>
    </div>
  );
}

export default Galerias;

*/










import React, { useState, useEffect, useContext } from 'react';
import Producto from './Producto';
import ProductoPlaceholder from './ProductoPlaceholder';
import { ProductsContext } from "../contextos/ProductsContext";
import { useNavigate } from "react-router-dom";
import FiltrosMotos from './FiltrosMotos';
import FiltrosAccesorios from './FiltrosAccesorios';

function Galerias() {
  const { productos } = useContext(ProductsContext);
  const [motosFilteredProducts, setMotosFilteredProducts] = useState([]);
  const [accesoriosFilteredProducts, setAccesoriosFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFilteredProducts, setLoadingFilteredProducts] = useState(false);

  const [filters, setFilters] = useState({
    motos: {
      tipoSubFamiliaSelect: "",
      tipoModelosSelect: "",
      colorSelect: "",
    },
    accesorios: {
      familiaSelect: "",
      colorSelect: "",
      tallaSelect: "",
    }
  });

  const [modelosMotos, setModelosMotos] = useState([]);
  const [subFamiliasMotos, setSubFamiliasMotos] = useState([]);
  const [coloresAccesorios, setColoresAccesorios] = useState([]);
  const [coloresMotos, setColoresMotos] = useState([]);
  const [tallasAccesorios, setTallasAccesorios] = useState([]);
  const [familiasAccesorios, setFamiliasAccesorios] = useState([]);

  const navigate = useNavigate();

  // Función para aplicar los filtros
  const applyFilters = (motos, accesorios) => {
    const filteredMotos = filterProducts(motos, filters.motos, 'motos');
    const filteredAccesorios = filterProducts(accesorios, filters.accesorios, 'accesorios');

    setMotosFilteredProducts(filteredMotos);
    setAccesoriosFilteredProducts(filteredAccesorios);

    const motoModelSet = new Set(filteredMotos.map((modelo) => modelo.modelo));
    setModelosMotos([...motoModelSet]);

    const colorSetMotos = new Set();
    filteredMotos.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1) colorSetMotos.add(producto.color1);
        if (producto.color2) colorSetMotos.add(producto.color2);
      });
    });
    setColoresMotos([...colorSetMotos]);

    const colorSetAccesorios = new Set();
    const tallaSetAccesorios = new Set();
    filteredAccesorios.forEach((modelo) => {
      modelo.productos.forEach((producto) => {
        if (producto.color1) colorSetAccesorios.add(producto.color1);
        if (producto.color2) colorSetAccesorios.add(producto.color2);
        if (producto.tallas && producto.tallas !== "Sin Talla") tallaSetAccesorios.add(producto.tallas);
      });
    });
    setColoresAccesorios([...colorSetAccesorios]);
    setTallasAccesorios([...tallaSetAccesorios]);
  };

  // Filtrar productos según los filtros seleccionados
  const filterProducts = (productos, filtros, tipo) => {
    let filteredProducts = productos;

    if (tipo === 'motos') {
      if (filtros.tipoSubFamiliaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_sub_familia === filtros.tipoSubFamiliaSelect)
        );
      }
      if (filtros.tipoModelosSelect) {
        filteredProducts = filteredProducts.filter(modelo => modelo.modelo === filtros.tipoModelosSelect);
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => 
            producto.color1 === filtros.colorSelect || producto.color2 === filtros.colorSelect)
        );
      }
    } else if (tipo === 'accesorios') {
      if (filtros.familiaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.descripcion_familia === filtros.familiaSelect)
        );
      }
      if (filtros.colorSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto =>
            producto.color1 === filtros.colorSelect || producto.color2 === filtros.colorSelect)
        );
      }
      if (filtros.tallaSelect) {
        filteredProducts = filteredProducts.filter(modelo =>
          modelo.productos.some(producto => producto.tallas === filtros.tallaSelect)
        );
      }
    }

    return filteredProducts;
  };

  // useEffect para aplicar filtros y obtener datos iniciales
  useEffect(() => {
    setIsLoading(true);
    const motos = [];
    const accesorios = [];

    Object.keys(productos).forEach((modelo) => {
      const modeloInfo = productos[modelo];
      const { productos: productList } = modeloInfo;
      const categoria = productList[0]?.categoria;
      if (categoria === "1009 MOTOS") {
        motos.push({ ...modeloInfo, modelo });
      } else if (categoria === "002 ACCESORIOS") {
        const modeloToUse = modelo === "Sin Modelo" ? productList[0]?.descripcion_familia : modelo;
        accesorios.push({ ...modeloInfo, modelo: modeloToUse });
      }
    });

    applyFilters(motos, accesorios);
    extractMotoSubFamilias(motos);
    extractFamiliasAccesorios(accesorios);

    setIsLoading(false);
  }, [productos, filters]);

  const extractMotoSubFamilias = (motos) => {
    const subFamiliaSet = new Set(motos.flatMap((modelo) =>
      modelo.productos?.map((producto) => producto.descripcion_sub_familia).filter(Boolean)
    ));
    setSubFamiliasMotos([...subFamiliaSet]);
  };

  const extractFamiliasAccesorios = (accesorios) => {
    const familiaSet = new Set(accesorios?.flatMap((modelo) =>
      modelo.productos?.map((producto) => producto.descripcion_familia).filter(Boolean)
    ));
    setFamiliasAccesorios([...familiaSet]);
  };

  const handleFilterChange = (e, category, filter) => {
    setLoadingFilteredProducts(true);
    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [filter]: e.target.value
      }
    });
  };

  const handleClick = (categoria, modelo, descripcion, claveBusqueda) => {
    const base = categoria === "1009 MOTOS" ? "/productos?modelo=" : "/productos?accesorio=";
    const query = encodeURIComponent(claveBusqueda.trim());
    const url = base + query;
    navigate(url);
  };

  const handleCategoryChangeMotos = (selectedCategory) => {
    setFilters({
      ...filters,
      motos: {
        ...filters.motos, // Mantén el resto de los filtros
        tipoSubFamiliaSelect: selectedCategory, // Asegúrate de usar la clave correcta
        tipoModelosSelect: '', // Resetea el filtro de modelos
        colorSelect: '' // Resetea el filtro de colores
      }
    });
  };
  
  
  const handleCategoryChangeAccesorios = (selectedCategory) => {
    setFilters({
      ...filters,
      accesorios: {
        ...filters.accesorios, // Mantén el resto de los filtros
        familiaSelect: selectedCategory, // Asegúrate de actualizar la categoría seleccionada
        colorSelect: '', // Resetea el filtro de colores
        tallaSelect: ''  // Resetea el filtro de tallas
      }
    });
  };
  

  const renderProductsOrPlaceholders = (isLoading, filteredProducts, loadingFilteredProducts) => {
    if (isLoading) {
      return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
    }

    if (filteredProducts.length > 0) {
      return filteredProducts.map((modelo) => (
        <div key={modelo.modelo} className="item_product">
          <Producto
            img={modelo.productos[0].hs_images}
            nombre={modelo.modelo}
            precio={modelo.precioMinimo}
            tipo={modelo.productos[0].descripcion_sub_familia || modelo.productos[0].descripcion_familia || "Sin tipo"}
            stock={modelo.stockTotal || "0"}
            isAvailable={parseFloat(modelo.stockTotal) > 0}
            handleClick={() =>
              handleClick(modelo.productos[0].categoria, modelo.productos[0].modelo, modelo.productos[0].descripcion, modelo.claveBusqueda)
            }
          />
        </div>
      ));
    } else if (loadingFilteredProducts) {
      return <p>No se encontraron productos para los filtros seleccionados.</p>;
    } else {
      return Array.from({ length: 10 }).map((_, index) => <ProductoPlaceholder key={index} />);
    }
  };

  return (
    <div className="galerias">
      <h3 className="cat-titulo">MOTOS</h3>
      <FiltrosMotos
        filtros={filters.motos}
        subFamiliasMotos={subFamiliasMotos}
        modelosMotos={modelosMotos}
        coloresMotos={coloresMotos}
        setModelosMotos={setModelosMotos}
        setColoresMotos={setColoresMotos}
        setFiltros={(newFilters) => setFilters({ ...filters, motos: newFilters })}
        handleFilterChange={handleFilterChange}
        handleCategoryChangeMotos={handleCategoryChangeMotos}
      />
      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, motosFilteredProducts, loadingFilteredProducts)}
      </div>

      <h3 className="cat-titulo">ACCESORIOS</h3>
      <FiltrosAccesorios
        filtros={filters.accesorios}
        familiasAccesorios={familiasAccesorios}
        coloresAccesorios={coloresAccesorios}
        tallasAccesorios={tallasAccesorios}
        setFiltros={(newFilters) => setFilters({ ...filters, accesorios: newFilters })}
        handleFilterChange={handleFilterChange}
        handleCategoryChangeAccesorios={handleCategoryChangeAccesorios}
      />
      <div className="galeria">
        {renderProductsOrPlaceholders(isLoading, accesoriosFilteredProducts, loadingFilteredProducts)}
      </div>
    </div>
  );
}

export default Galerias;