import "./App.css";
import Home from "./pages/Home";
import { CartProvider } from "./contextos/CartContext";
import { AuthProvider } from "./contextos/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetallesProducto from "./pages/DetallesProducto";
import Detalles from "./components/Detalles";
import CarritoPage from "./pages/Carrito";
import Error from "./pages/Error";
import MisCompras from "./pages/MisCompras";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ProductsProvider } from "./contextos/ProductsContext";
import { NotificationProvider } from "./contextos/NotificationContext";

function App() {
  return (
    <AuthProvider>
      <NotificationProvider>
        <CartProvider>
            <Router>
                <ProductsProvider>
                  <Header />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="productos" element={<Detalles />} />
                    <Route path="carrito" element={<CarritoPage />} />
                    <Route path="miscompras" element={<MisCompras />} />
                    <Route path="*" element={<Error />} />
                  </Routes>
                  <Footer />
                </ProductsProvider>
            </Router>
        </CartProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
