/***************************************************/
/* Contexto consume productos en cache en servidor */
/***************************************************/



import React, { createContext, useState, useEffect, useMemo } from 'react';

const ProductsContext = createContext();

const ProductsProvider = ({ children }) => {
  const [productos, setProductos] = useState([]); // Productos completos
  const [productosRapidos, setProductosRapidos] = useState([]); // Primeros productos rápidos
  const [restoProductos, setRestoProductos] = useState([]); // Resto de los productos
  const [stockLimit, setStockLimit] = useState(0);

  const limpiarNombre = (name) => {
    if (!name) return 'Sin Nombre';
    return name
      .replace(/\(.*?\)/g, '')
      .replace(/\b(\d+\s?(ml|cm|mm|kg|L|M|S|XS|XL))\b/gi, '')
      .replace(/\b(?:NEGRO|BLANCO|ROJO|AZUL|VERDE|ANARANJADO|GRIS|MORADO|CAFÉ|AMARILLO|CELESTE|NARANJA|ROSA|VIOLETA)\b/gi, '')
      .replace(/\bTALLA\b\s*[A-Z]+/gi, '')
      .replace(/[_-]+/g, ' ')
      .trim();
  };

  const extraerColoresAccesorios = (name) => {
    if (!name) return { color1: 'Sin Color', color2: '' };
    const match = name.match(/\b(NEGRO|BLANCO|ROJO|AZUL|VERDE|ANARANJADO|GRIS|MORADO|CAFÉ|AMARILLO|CELESTE|NARANJA|ROSA|VIOLETA)\b/gi);
    return {
      color1: match ? match[0] : 'Sin Color',
      color2: match && match.length > 1 ? match[1] : '' 
    };
  };

  const extraerTallas = (name) => {
    if (!name) return '';
    
    // Expresión regular para encontrar "TALLA <tamaño>", con o sin paréntesis
    const match = name.match(/\(?TALLA\s([A-Z]+)\)?/); 
  
    return match ? `${match[1]}` : '';
  };

  const agruparPorModeloYNombre = (items) => {
    if (!Array.isArray(items)) {
      console.error('Expected an array but received:', items);
      return {};
    }

    const productosAgrupados = items.reduce((grupos, producto) => {
      const categoria = producto.properties.categoria || 'Sin Categoría';
      const esAccesorio = categoria === "002 ACCESORIOS";

      const claveAgrupacion = esAccesorio 
        ? limpiarNombre(producto.properties.name) 
        : producto.properties.modelo || 'Sin Modelo';

      if (!grupos[claveAgrupacion]) {
        grupos[claveAgrupacion] = {
          productos: [],
          stockTotal: 0,
          precioMinimo: parseFloat(producto.properties.price) || 0,
          claveBusqueda: claveAgrupacion,
        };
      }

      const stockActual = parseInt(producto.properties.stock, 10) || 0;
      const precioActual = parseFloat(producto.properties.price) || 0;

      grupos[claveAgrupacion].stockTotal += stockActual;
      if (precioActual < grupos[claveAgrupacion].precioMinimo) {
        grupos[claveAgrupacion].precioMinimo = precioActual;
      }

      const colores = esAccesorio ? extraerColoresAccesorios(producto.properties.name) : {
        color1: producto.properties.color1 || 'Sin Color',
        color2: producto.properties.color2 || 'Sin Color',
      };

      grupos[claveAgrupacion].productos.push({
        id: producto.id,
        sku: producto.properties.hs_sku,
        color1: colores.color1, 
        color2: colores.color2,  
        stock: stockActual,
        descripcion: producto.properties.description ? limpiarNombre(producto.properties.description) : 'Sin Descripción',
        categoria: producto.properties.categoria || 'Sin Categoría',
        descripcion_familia: producto.properties.descripcion_familia || 'Sin Familia',
        descripcion_sub_familia: producto.properties.descripcion_sub_familia || 'Sin Subfamilia',
        price: precioActual,
        id_producto: producto.properties.id_producto,
        modelo: producto.properties.modelo || "Sin Modelo",
        hs_images: producto.properties.hs_images || null,
        name: producto.properties.name || 'Sin Nombre',
        ficha_tecnica: producto.properties.link_ficha_tecnica,
        tallas: producto.properties.categoria === "002 ACCESORIOS" ? extraerTallas(producto.properties.description) : 'Sin Talla',
        imagenes: producto.properties.imagenes
      });

      return grupos;
    }, {});

    return productosAgrupados;
  };

  // Memoizar productos agrupados
  const productosAgrupados = useMemo(() => agruparPorModeloYNombre([...productosRapidos, ...restoProductos]), [productosRapidos, restoProductos]);

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        // Primera llamada: Obtener productos desde /api/products (caché o HubSpot)
        const response = await fetch(process.env.REACT_APP_SERVERURL + "/api/products", {
          method: "GET",
        });

        const data = await response.json();
        if (Array.isArray(data)) {
          const primerosProductos = data.slice(0, 4); // Ejemplo, los primeros 4 productos
          const restoDeProductos = data.slice(4);

          setProductosRapidos(primerosProductos); // Guardar primeros productos rápidamente
          setRestoProductos(restoDeProductos); // Guardar el resto de productos
        }
      } catch (e) {
        console.error('Error fetching products:', e);
      }
    };

    fetchProductos();
  }, [stockLimit]);

  return (
    <ProductsContext.Provider
      value={{
        productos: productosAgrupados, // Todos los productos (rápidos + el resto)
        stockLimit,
        setStockLimit 
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsProvider };
