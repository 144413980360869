/*
import { useEffect } from 'react';

const ScrollToTopOnClick = () => {
  useEffect(() => {
    const handleScrollToTop = (event) => {
      if (event.target.matches('.buy-now', 'button.btn.verde[type=submit]')) {
        console.log("subiendo...");
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Para un scroll suave
        });
      }
    };

    document.addEventListener('click', handleScrollToTop);

    // Cleanup
    return () => {
      document.removeEventListener('click', handleScrollToTop);
    };
  }, []);

  return null; // Este componente no necesita renderizar nada
};

export default ScrollToTopOnClick;
*/

import { useEffect } from 'react';

const ScrollToTopOnClick = () => {
  useEffect(() => {
    const handleScrollToTop = (event) => {
      if (event.target.matches('.buy-now, .send','.btn-clear-cart','.btn-continue-shopping', '.cart_products_list a.btn.verde.inline')) {
        console.log("subiendo...");
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Para un scroll suave
        });
      }
    };

    document.addEventListener('click', handleScrollToTop);

    // Cleanup
    return () => {
      document.removeEventListener('click', handleScrollToTop);
    };
  }, []);

  return null; // Este componente no necesita renderizar nada
};

export default ScrollToTopOnClick;
