import React, { createContext, useState, useEffect, useCallback, useRef } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [esperando, setEsperando] = useState(false);
  const logoutTimer = useRef(null);

  const handleLogin = async (email) => {
    setEsperando(true);
    try {
      localStorage.setItem("correoElectronico", email);
      setEmail(email);
      setIsLoggedIn(true);
      startLogoutTimer();
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setEsperando(false);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setEmail("");
    localStorage.removeItem("correoElectronico");
    localStorage.removeItem("paymentUrl");
    localStorage.removeItem("shippingInfo");
    localStorage.removeItem("cart");
    clearLogoutTimer();
  };

  const startLogoutTimer = () => {
    clearLogoutTimer();
    logoutTimer.current = setTimeout(handleLogout, 3600000);
  };

  const clearLogoutTimer = () => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
  };

  const resetLogoutTimer = useCallback(() => {
    if (isLoggedIn) {
      startLogoutTimer();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const savedEmail = localStorage.getItem("correoElectronico");
    if (savedEmail) {
      setEmail(savedEmail);
      setIsLoggedIn(true);
      startLogoutTimer();
    }

    window.addEventListener("mousemove", resetLogoutTimer);
    window.addEventListener("keydown", resetLogoutTimer);

    return () => {
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keydown", resetLogoutTimer);
      clearLogoutTimer();
    };
  }, [resetLogoutTimer]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, email, esperando, handleLogin, handleLogout, setEsperando, setEmail }}>
      {children}
    </AuthContext.Provider>
  );
};
