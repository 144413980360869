/*
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import { ProductsContext } from "../contextos/ProductsContext";
import { useNotification } from '../contextos/NotificationContext';

const ProductoCarrito = ({ producto }) => {
  const { updateProductQuantity, removeFromCart, sold, recalculateShippingCost } = useContext(CartContext);
  const { productos } = useContext(ProductsContext);
  const [cantidad, setCantidad] = useState(producto.cant);
  const { notify } = useNotification();

  console.log(sold);

  useEffect(() => {
    setCantidad(producto.cant);
  }, [producto.cant]);

  const incrementarCantidad = () => {
    if (productos) {
      const allProducts = Object.values(productos).flat();
      const product = allProducts.find(p => p.properties.hs_object_id === producto.id);

      if (product && cantidad < parseInt(product.properties.stock, 10)) {
        const nuevaCantidad = cantidad + 1;
        setCantidad(nuevaCantidad);
        updateProductQuantity(producto.id, nuevaCantidad);
        recalculateShippingCost(); // Asegúrate de que esta función esté definida en CartContext
      } else {
        notify(`El límite máximo de cantidades para el producto ${producto.name} es: ${product?.properties?.stock}`);
      }
    }
  };

  const disminuirCantidad = () => {
    if (cantidad > 1) {
      const nuevaCantidad = cantidad - 1;
      setCantidad(nuevaCantidad);
      updateProductQuantity(producto.id, nuevaCantidad);
      recalculateShippingCost(); // Asegúrate de que esta función esté definida en CartContext
    }
  };

  const handleRemoveItem = () => {
    removeFromCart(producto.id);
    recalculateShippingCost(); // Asegúrate de que esta función esté definida en CartContext
  };

  const price = parseFloat(producto.price) || 0;

  return (
    <div className="producto-carrito" data-items-min={cantidad >= 3 && "true"}>
      <div className="producto-info">
        {producto.img && producto.img.includes("http") && cantidad < 3 && (
          <img src={producto.img} alt={producto.name} width="100" />
        )}
        <div>
          <p>{producto.name}</p>
          <p className="itemPrice">${price.toFixed(2)}</p>
        </div>
        {sold === true ? (
          <span className="no_controls">
            <span>{cantidad} / uni.</span>
          </span>
        ) : null}
      </div>
      {sold === true ? null : (
        <div className="cant-buttons">
          <button className="btn-decrease" onClick={disminuirCantidad}>-</button>
          <span>{cantidad}</span>
          <button className="btn-increase" onClick={incrementarCantidad}>+</button>
          <button className="btn-clear" onClick={handleRemoveItem}>X</button>
        </div>
      )}
    </div>
  );
};

export default ProductoCarrito;
*/







import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import { ProductsContext } from "../contextos/ProductsContext";
import { useNotification } from '../contextos/NotificationContext';

const ProductoCarrito = ({ producto }) => {
  const { updateProductQuantity, removeFromCart, sold, recalculateShippingCost } = useContext(CartContext);
  const { productos } = useContext(ProductsContext);
  const [cantidad, setCantidad] = useState(producto.cant);
  const { notify } = useNotification();

  useEffect(() => {
    setCantidad(producto.cant);
  }, [producto.cant]);

  const incrementarCantidad = () => {
    if (productos) {
      const allProducts = Object.values(productos).flat();
      const product = allProducts.find(p => p.productos.some(item => item.sku === producto.sku)); // Ajuste aquí

      // Asegúrate de que se está accediendo correctamente al stock del producto
      const itemProducto = product?.productos?.find(item => item.sku === producto.sku);

      if (itemProducto && itemProducto.stock !== undefined && cantidad < parseInt(itemProducto.stock, 10)) {
        const nuevaCantidad = cantidad + 1;
        setCantidad(nuevaCantidad);
        updateProductQuantity(producto.sku, nuevaCantidad); // Actualizar cantidad por SKU
        recalculateShippingCost(); // Recalcular el costo de envío
      } else {
        const stockDisponible = itemProducto?.stock || 0;
        notify(`El límite máximo de cantidades para el producto ${producto.name} es: ${stockDisponible}`);
      }
    }
  };

  const disminuirCantidad = () => {
    if (cantidad > 1) {
      const nuevaCantidad = cantidad - 1;
      setCantidad(nuevaCantidad);
      updateProductQuantity(producto.sku, nuevaCantidad); // Disminuir cantidad por SKU
      recalculateShippingCost(); // Recalcular el costo de envío
    }
  };

  const handleRemoveItem = () => {
    removeFromCart(producto.sku); // Eliminar por SKU
    recalculateShippingCost(); // Recalcular el costo de envío
  };

  const price = parseFloat(producto.price) || 0;

  return (
    <div className="producto-carrito" data-items-min={cantidad >= 3 && "true"}>
      <div className="producto-info">
        <table>
          <tbody>
            <tr>
              {producto.img && producto.img.includes("http") && cantidad < 3 && (
                <td><img src={producto.img} alt={producto.name} width="100" /></td>
              )}
              <td className="descripcion">{producto.description}</td>
              <td className="colorTalla">
                {producto.categoria === "002 ACCESORIOS" && (
                  <>
                    {/* Mostrar colores si existen */}
                    {producto.colores_accesorios && producto.colores_accesorios !== "Sin colores en accesorios" && (
                      <span>{producto.colores_accesorios}</span>
                    )}
                    {/* Mostrar talla si existe */}
                    {producto.tallas && producto.tallas !== "Sin Talla" && (
                      <span>{" Talla " + producto.tallas}</span>
                    )}
                  </>
                )}
              </td>
              <td className="itemPrice">${price.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        {sold === true ? (
          <span className="no_controls">
            <span>{cantidad} / uni.</span>
          </span>
        ) : null}
      </div>
      {sold === true ? null : (
        <div className="cant-buttons">
          <button className="btn-decrease" onClick={disminuirCantidad}>-</button>
          <span>{cantidad}</span>
          <button className="btn-increase" onClick={incrementarCantidad}>+</button>
          <button className="btn-clear" onClick={handleRemoveItem}>X</button>
        </div>
      )}
    </div>
  );
};

export default ProductoCarrito;