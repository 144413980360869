import React from 'react'


function Galerias() {
    return (
        <div className="cont-branding">
        <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0000_piaggio.png" alt="Piaggio" />
        <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0001_mtm.png" alt="MTM" />
        <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/logo-ALFAMETAL-horizontal-270x80.png" alt="Alfametal" />
        <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0004_Capa-1.png" alt="Capa 1" />
        <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0002_metaltronic_groupactual.png" alt="Metaltronic" />
      </div>
    )
}

export default Galerias