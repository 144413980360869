/* Con detalle de IVA */


import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../contextos/CartContext";
import { useNavigate } from 'react-router-dom';
import EnvioDetalles from './EnvioDetalles';

const ResumenCarrito = () => {
  const { cart, shippingInfo, clearCart, sold, url } = useContext(CartContext);
  const navigate = useNavigate();

  const [urlPayment, setUrlPayment] = useState(url);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [baseTaxes, setBaseTaxes] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);

  useEffect(() => {
    setUrlPayment(url);
  }, [url]);

  const roundAmount = (value) => {
    const decimalPlaces = 2; // Siempre dos decimales para USD
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(value * factor) / factor;
  };

  useEffect(() => {
    // Cálculo del subtotal y total
    const productos = Object.values(cart);
    const subtotalCalc = productos.reduce((acc, producto) => {
      const price = parseFloat(producto.price) || 0;
      return acc + price * producto.cant;
    }, 0);
    
    // Calcular base y total de impuestos
    const baseTaxesCalc = subtotalCalc / 1.15; // Asumiendo 15% de IVA
    const totalTaxesCalc = baseTaxesCalc * 0.15;

    const totalCalc = subtotalCalc + (parseFloat(shippingInfo?.shippingCost) || 0);

    // Guardar los valores redondeados
    setSubtotal(roundAmount(subtotalCalc));
    setBaseTaxes(roundAmount(baseTaxesCalc));
    setTotalTaxes(roundAmount(totalTaxesCalc));
    setTotal(roundAmount(totalCalc));
  }, [cart, shippingInfo]);

  const handleClearCart = () => {
    clearCart();
    localStorage.removeItem("cart");
    localStorage.removeItem("shippingInfo");
    localStorage.removeItem("ultimaCompra");
  };

  const handleContinueShopping = () => {
    navigate('/');
  };

  return (
    <div className="checkout_container">
      <div className="resumen-carrito">
        <p className="cart_subtotal">Subtotal (IVA incluído): <span>${subtotal.toFixed(2)}</span></p>
        <p className="cart_iva">IVA (15%): <span>${totalTaxes.toFixed(2)}</span></p>
        <EnvioDetalles />
        <p className="cart_total">Total: <span>${total.toFixed(2)}</span></p>
        {!sold && (
          <div className="additional-buttons">
            <button className="btn-clear-cart" onClick={handleClearCart}>Limpiar carrito</button>
            <button className="btn-continue-shopping" onClick={handleContinueShopping}>Seguir comprando</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumenCarrito;
