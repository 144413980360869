/*
import React from 'react';

function FiltrosMotos({ filtros, subFamiliasMotos, modelosMotos, coloresMotos, handleFilterChange }) {

  return (
    <div className="filter_container select-filter">
      <select onChange={(e) => handleFilterChange(e, 'motos', 'tipoSubFamiliaSelect')} value={filtros.tipoSubFamiliaSelect}>
        <option value="">Todas las categorías</option>
        {subFamiliasMotos?.length > 0 
          ? subFamiliasMotos.map((subFamilia) => (
              <option key={subFamilia} value={subFamilia}>{subFamilia}</option>
            )) 
          : <option value="">No hay categorías disponibles</option>
        }
      </select>

      <select onChange={(e) => handleFilterChange(e, 'motos', 'tipoModelosSelect')} value={filtros.tipoModelosSelect}>
        <option value="">Todos los modelos</option>
        {modelosMotos?.length > 0 ? (
          modelosMotos.map((modelo) => (
            <option key={modelo} value={modelo}>
              {modelo}
            </option>
          ))
        ) : (
          <option value="">No hay modelos disponibles</option>
        )}
      </select>

      <select onChange={(e) => handleFilterChange(e, 'motos', 'colorSelect')} value={filtros.colorSelect}>
        <option value="">Todos los colores</option>
        {coloresMotos?.length > 0 ? (
          coloresMotos.map((color, index) => (
            <option key={index} value={color}>
              {color}
            </option>
          ))
        ) : (
          <option value="">No hay colores disponibles</option>
        )}
      </select>
    </div>
  );
}

export default FiltrosMotos;

*/




import React from 'react';

function FiltrosMotos({ filtros, subFamiliasMotos, modelosMotos, coloresMotos, handleFilterChange, handleCategoryChangeMotos }) {

  return (
    <div className="filter_container select-filter">
      <select onChange={(e) => handleCategoryChangeMotos(e.target.value)} value={filtros.tipoSubFamiliaSelect}>
        <option value="">Todas las categorías</option>
        {subFamiliasMotos?.length > 0 
          ? subFamiliasMotos.map((subFamilia) => (
              <option key={subFamilia} value={subFamilia}>{subFamilia}</option>
            )) 
          : <option value="">No hay categorías disponibles</option>
        }
      </select>
      <select onChange={(e) => handleFilterChange(e, 'motos', 'tipoModelosSelect')} value={filtros.tipoModelosSelect}>
        <option value="">Todos los modelos</option>
        {modelosMotos?.length > 0 ? (
          modelosMotos.map((modelo) => (
            <option key={modelo} value={modelo}>
              {modelo}
            </option>
          ))
        ) : (
          <option value="">No hay modelos disponibles</option>
        )}
      </select>
      <select onChange={(e) => handleFilterChange(e, 'motos', 'colorSelect')} value={filtros.colorSelect}>
        <option value="">Todos los colores</option>
        {coloresMotos?.length > 0 ? (
          coloresMotos.map((color, index) => (
            <option key={index} value={color}>
              {color}
            </option>
          ))
        ) : (
          <option value="">No hay colores disponibles</option>
        )}
      </select>
    </div>
  );
}

export default FiltrosMotos;
