
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ProductsContext } from "../contextos/ProductsContext";
import { CartContext } from "../contextos/CartContext";
import Brands from './Brands';

function Detalles() {
  const location = useLocation();
  const { productos } = useContext(ProductsContext);
  const { addToCart } = useContext(CartContext); // Importamos addToCart del contexto del carrito
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [versionSeleccionada, setVersionSeleccionada] = useState(null);
  const [coloresDisponibles, setColoresDisponibles] = useState([]);
  const [tallasDisponibles, setTallasDisponibles] = useState([]);
  const [addedToCart, setAddedToCart] = useState(false);

  const normalizarTexto = (texto) => {
    if (!texto) return '';
    return texto.replace(/\s+/g, ' ').trim(); // Reemplaza múltiples espacios por un solo espacio y elimina espacios al inicio y al final
  };

  const obtenerQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    const accesorio = queryParams.get("accesorio");
    const modelo = queryParams.get("modelo");
    return accesorio ? decodeURIComponent(accesorio) : modelo ? decodeURIComponent(modelo) : null;
  };

  useEffect(() => {
    const queryParam = obtenerQueryParam();
    if (productos && queryParam) {
      const claveBusquedaNormalizada = normalizarTexto(queryParam);
      const productoEncontrado = Object.values(productos).find(p =>
        normalizarTexto(p.claveBusqueda) === claveBusquedaNormalizada
      );

      if (productoEncontrado) {
        setProductoSeleccionado(productoEncontrado);
        setVersionSeleccionada(productoEncontrado.productos[0]);

        // Filtrar combinaciones únicas de colores (color1 y color2)
        const coloresUnicos = productoEncontrado.productos
          .map(producto => ({ color1: producto.color1, color2: producto.color2 }))
          .filter((color, index, self) =>
            index === self.findIndex(c => c.color1 === color.color1 && c.color2 === color.color2) // Filtra colores únicos
          );

        const tallas = productoEncontrado.productos
          .map((producto) => producto.tallas)
          .filter((talla, index, self) => talla && self.indexOf(talla) === index); // Eliminar duplicados

        setColoresDisponibles(coloresUnicos);
        setTallasDisponibles(tallas);
      } else {
        setProductoSeleccionado(null);
      }
    }
  }, [productos]);

  const handleColorChange = (producto) => {
    setVersionSeleccionada(producto); // Cambiar a la versión seleccionada
    const nuevasTallas = productoSeleccionado.productos
      .filter(p => p.color1 === producto.color1 && p.color2 === producto.color2)
      .map(p => p.tallas)
      .filter((talla, index, self) => talla && self.indexOf(talla) === index);
    setTallasDisponibles(nuevasTallas);

    // Imprimir en consola el detalle del producto seleccionado con colores
    console.log(`Producto seleccionado: ${producto.name}, Color: ${producto.color1} / ${producto.color2}`);
  };

  const handleTallaChange = (tallaSeleccionada) => {
    const productoConTalla = productoSeleccionado.productos.find(producto =>
      producto.tallas === tallaSeleccionada &&
      producto.color1 === versionSeleccionada.color1 &&
      producto.color2 === versionSeleccionada.color2
    );
    if (productoConTalla) {
      setVersionSeleccionada(productoConTalla);
      
      // Imprimir en consola el detalle del producto seleccionado con talla
      console.log(`Producto seleccionado: ${productoConTalla.name}, Color: ${productoConTalla.color1} / ${productoConTalla.color2}, Talla: ${productoConTalla.tallas}`);
    }
  };

  const handleButtonClick = () => {
    if (addedToCart) {
      window.location.href = "/carrito";
    } else {
      // Imprimir en consola la versión seleccionada al agregar al carrito
      console.log(`Agregando al carrito: ${versionSeleccionada.name}, Color: ${versionSeleccionada.color1} / ${versionSeleccionada.color2}, Talla: ${versionSeleccionada.tallas || "No Aplica"}`);
      
      addToCart({
        id: versionSeleccionada.id,
        sku: versionSeleccionada.sku,
        description: versionSeleccionada.descripcion,
        name: versionSeleccionada.name,
        price: versionSeleccionada.price,
        img: versionSeleccionada.hs_images,
        descripcion_familia: versionSeleccionada.descripcion_familia,
        descripcion_sub_familia: versionSeleccionada.descripcion_sub_familia,
        series: versionSeleccionada.series || null,
        id_producto: versionSeleccionada.id_producto || null,
        categoria: versionSeleccionada.categoria,
        tallas: versionSeleccionada.tallas,
        ficha_tecnica: versionSeleccionada.ficha_tecnica,
        stock: versionSeleccionada.stock
      });
      setAddedToCart(true);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const obtenerColores = (producto) => {
    return {
      color1: producto.color1 || "Sin Color",
      color2: producto.color2 || ""
    };
  };

  return (
    <>
      {productoSeleccionado ? (
        <div className="cont-detalle flex-column">
          <img className={versionSeleccionada && versionSeleccionada.hs_images ? "image" : "image notImage"} src={versionSeleccionada && versionSeleccionada.hs_images ? versionSeleccionada.hs_images : "./NotImage.jpg"} alt={versionSeleccionada.name} />
          <div className="productDetails">
            {versionSeleccionada && (
              <div>
                <h2>{productoSeleccionado.claveBusqueda}</h2>
                <p>{versionSeleccionada.descripcion}</p>
                <p>Precio: {formatCurrency(versionSeleccionada.price)}</p>
                <p>Stock: {versionSeleccionada.stock}</p>
              </div>
            )}

            {versionSeleccionada.color1 !== "Sin Color" && (
              <ul className="productColors">
                {coloresDisponibles.map((color, index) => {
                  return (
                    <li key={`${color.color1}-${color.color2}-${index}`}>
                      <button
                        className={`color-button ${versionSeleccionada.color1 === color.color1 && versionSeleccionada.color2 === color.color2 ? "active" : ""}`}
                        data-color1={color.color1}
                        data-color2={color.color2}
                        onClick={() => handleColorChange(productoSeleccionado.productos.find(p => p.color1 === color.color1 && p.color2 === color.color2))}
                      >
                        <span></span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}

            {tallasDisponibles != "Sin Talla" && (
              <ul className="productSizes">
                {tallasDisponibles.map((talla, index) => (
                  <li key={`${talla}-${index}`}>
                    <button
                      className={`talla-button ${versionSeleccionada.tallas === talla ? "active" : ""}`}
                      onClick={() => handleTallaChange(talla)}
                    >
                      {talla}
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <div className="btn_container flex">
              {versionSeleccionada && (
                versionSeleccionada.stock > 0 ? (
                  <button className="btn" onClick={handleButtonClick}>
                    {addedToCart ? "Ir al Carrito" : "Agregar al Carrito"}
                  </button>
                ) : (
                  <span className="agotado">AGOTADO</span>
                )
              )}

              {versionSeleccionada && versionSeleccionada.ficha_tecnica && versionSeleccionada.ficha_tecnica !== "" && (
                <a
                  className="btn fichaTecnica"
                  href={versionSeleccionada.ficha_tecnica}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={versionSeleccionada.descripcion}
                >
                  Ficha Técnica
                </a>
              )}
            </div>
              <p>*Nota: Para un proceso ágil de matriculación, solo se permite una moto por carrito de compra. Si deseas adquirir más de una moto, deberás realizar compras por separado.</p>
          </div>
        </div>
      ) : (
        <p>No se encontró el producto.</p>
      )}
      <Brands />
    </>
  );
}

export default Detalles;





/* Con Imagenes extra */
/*
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ProductsContext } from "../contextos/ProductsContext";
import { CartContext } from "../contextos/CartContext";
import Brands from './Brands';

function Detalles() {
  const location = useLocation();
  const { productos } = useContext(ProductsContext);
  const { addToCart } = useContext(CartContext);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [versionSeleccionada, setVersionSeleccionada] = useState(null);
  const [coloresDisponibles, setColoresDisponibles] = useState([]);
  const [tallasDisponibles, setTallasDisponibles] = useState([]);
  const [thumbsDisponibles, setThumbsDisponibles] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState("");
  const [addedToCart, setAddedToCart] = useState(false);

  const normalizarTexto = (texto) => {
    if (!texto) return '';
    return texto.replace(/\s+/g, ' ').trim();
  };

  const obtenerQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    const accesorio = queryParams.get("accesorio");
    const modelo = queryParams.get("modelo");
    return accesorio ? decodeURIComponent(accesorio) : modelo ? decodeURIComponent(modelo) : null;
  };

  useEffect(() => {
    const queryParam = obtenerQueryParam();
    if (productos && queryParam) {
      const claveBusquedaNormalizada = normalizarTexto(queryParam);
      const productoEncontrado = Object.values(productos).find(p =>
        normalizarTexto(p.claveBusqueda) === claveBusquedaNormalizada
      );

      if (productoEncontrado) {
        setProductoSeleccionado(productoEncontrado);
        const versionInicial = productoEncontrado.productos[0];
        setVersionSeleccionada(versionInicial);
        setImagenPrincipal(versionInicial.hs_images);

        // Filtrar combinaciones únicas de colores (color1 y color2)
        const coloresUnicos = productoEncontrado.productos
          .map(producto => ({ color1: producto.color1, color2: producto.color2 }))
          .filter((color, index, self) =>
            index === self.findIndex(c => c.color1 === color.color1 && c.color2 === color.color2)
          );

        const tallas = productoEncontrado.productos
          .map((producto) => producto.tallas)
          .filter((talla, index, self) => talla && self.indexOf(talla) === index);

        setColoresDisponibles(coloresUnicos);
        setTallasDisponibles(tallas);

        // Agregar imágenes adicionales si existen en la propiedad 'imagenes'
        const thumbnails = [];
        if (versionInicial.hs_images) thumbnails.push(versionInicial.hs_images);
        if (versionInicial.imagenes) {
          const imagenesExtras = versionInicial.imagenes.split(',').map(img => img.trim());
          thumbnails.push(...imagenesExtras);
        }
        setThumbsDisponibles(thumbnails);
      } else {
        setProductoSeleccionado(null);
      }
    }
  }, [productos]);

  const handleColorChange = (producto) => {
    setVersionSeleccionada(producto);
    setImagenPrincipal(producto.hs_images);

    const nuevasTallas = productoSeleccionado.productos
      .filter(p => p.color1 === producto.color1 && p.color2 === producto.color2)
      .map(p => p.tallas)
      .filter((talla, index, self) => talla && self.indexOf(talla) === index);
    setTallasDisponibles(nuevasTallas);

    // Actualizar los thumbnails con imágenes adicionales si existen
    const thumbnails = [];
    if (producto.hs_images) thumbnails.push(producto.hs_images);
    if (producto.imagenes) {
      const imagenesExtras = producto.imagenes.split(',').map(img => img.trim());
      thumbnails.push(...imagenesExtras);
    }
    setThumbsDisponibles(thumbnails);
  };

  const handleTallaChange = (tallaSeleccionada) => {
    const productoConTalla = productoSeleccionado.productos.find(producto =>
      producto.tallas === tallaSeleccionada &&
      producto.color1 === versionSeleccionada.color1 &&
      producto.color2 === versionSeleccionada.color2
    );
    if (productoConTalla) {
      setVersionSeleccionada(productoConTalla);
    }
  };

  const handleThumbnailClick = (thumbnail) => {
    setImagenPrincipal(thumbnail);
  };

  const handleButtonClick = () => {
    if (addedToCart) {
      window.location.href = "/carrito";
    } else {
      addToCart({
        id: versionSeleccionada.id,
        sku: versionSeleccionada.sku,
        description: versionSeleccionada.descripcion,
        name: versionSeleccionada.name,
        price: versionSeleccionada.price,
        img: versionSeleccionada.hs_images,
        descripcion_familia: versionSeleccionada.descripcion_familia,
        descripcion_sub_familia: versionSeleccionada.descripcion_sub_familia,
        series: versionSeleccionada.series || null,
        id_producto: versionSeleccionada.id_producto || null,
        categoria: versionSeleccionada.categoria,
        tallas: versionSeleccionada.tallas,
        ficha_tecnica: versionSeleccionada.ficha_tecnica,
      });
      setAddedToCart(true);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <>
      {productoSeleccionado ? (
        <div className="cont-detalle flex-column">
        {thumbsDisponibles.length > 0 && (
          <div className="thumbnails-container">
            {thumbsDisponibles.map((thumb, index) => (
              <img 
                key={index} 
                src={thumb} 
                className="thumbnail" 
                alt={`thumb-${index}`} 
                onClick={() => handleThumbnailClick(thumb)} 
              />
            ))}
          </div>
        )}

          <img className={imagenPrincipal ? "image" : "image notImage"} src={imagenPrincipal || "./NotImage.jpg"} alt={versionSeleccionada.name} />

          <div className="productDetails">
            {versionSeleccionada && (
              <div>
                <h2>{productoSeleccionado.claveBusqueda}</h2>
                <p>{versionSeleccionada.descripcion}</p>
                <p>Precio: {formatCurrency(versionSeleccionada.price)}</p>
                <p>Stock: {versionSeleccionada.stock}</p>
              </div>
            )}

            {versionSeleccionada.color1 !== "Sin Color" && (
              <ul className="productColors">
                {coloresDisponibles.map((color, index) => (
                  <li key={`${color.color1}-${color.color2}-${index}`}>
                    <button
                      className={`color-button ${versionSeleccionada.color1 === color.color1 && versionSeleccionada.color2 === color.color2 ? "active" : ""}`}
                      data-color1={color.color1}
                      data-color2={color.color2}
                      onClick={() => handleColorChange(productoSeleccionado.productos.find(p => p.color1 === color.color1 && p.color2 === color.color2))}
                    >
                      <span></span>
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {tallasDisponibles.length > 0 && (
              <ul className="productSizes">
                {tallasDisponibles.map((talla, index) => (
                  <li key={`${talla}-${index}`}>
                    <button
                      className={`talla-button ${versionSeleccionada.tallas === talla ? "active" : ""}`}
                      onClick={() => handleTallaChange(talla)}
                    >
                      {talla}
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <div className="btn_container flex">
              {versionSeleccionada && (
                versionSeleccionada.stock > 0 ? (
                  <button className="btn" onClick={handleButtonClick}>
                    {addedToCart ? "Ir al Carrito" : "Agregar al Carrito"}
                  </button>
                ) : (
                  <span className="agotado">AGOTADO</span>
                )
              )}

              {versionSeleccionada && versionSeleccionada.ficha_tecnica && versionSeleccionada.ficha_tecnica !== "" && (
                <a
                  className="btn fichaTecnica"
                  href={versionSeleccionada.ficha_tecnica}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={versionSeleccionada.descripcion}
                >
                  Ficha Técnica
                </a>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No se encontró el producto.</p>
      )}
      <Brands />
    </>
  );
}

export default Detalles;
*/