
import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { CartContext } from '../../contextos/CartContext';
import { useNotification } from '../../contextos/NotificationContext';
import JsonCiudades from '../../data/listaPrecioEnvio.json';

const useFormularioCompra = () => {
  const { cart, clearCart, shippingInfo, setSold, setUrl, setShippingInfo } = useContext(CartContext);
  const { notify } = useNotification();
  const ciudades = JsonCiudades.ciudades || [];

  const [purchase, setPurchase] = useState(cart);
  const [dealId, setDealId] = useState(0);
  const [esperando, setEsperando] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [penddingDeals, setPenddingDeals] = useState(null);

  const paymentUrlData = JSON.parse(localStorage.getItem("paymentUrl") || '{}');
  const expirationTime = JSON.parse(localStorage.getItem("expiration") || 'null');
  
  const paymentUrl = paymentUrlData ? paymentUrlData.value : '';
  const [urlForm, setUrlForm] = useState(paymentUrl);
  const [expiration, setExpiration] = useState(expirationTime);

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    document: '',
    email: '',
    mobile: '+593',
    typedocument: 'CI',
    ciudad: '',
    comunicaciones: false
  });

  const [errors, setErrors] = useState({});
  const timeoutRef = useRef(null);

  const calculateShippingCost = (city) => {
    const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === city);
    if (!selectedCity) return { shippingCost: 0, shippingTime: '' };

    let totalCost = 0;

    if (shippingInfo.shippingType === "otro") {
      for (const item of Object.values(cart)) {
        const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
        totalCost += cost;
      }
    } else {
      totalCost = 0;
    }

    return { shippingCost: totalCost, shippingTime: selectedCity["Tiempo de entrega horas"] };
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'ciudad') {
      const { shippingCost, shippingTime } = calculateShippingCost(value);

      setShippingInfo({
        ...shippingInfo,
        shippingCity: value,
        shippingCost: shippingInfo.shippingType === 'otro' ? shippingCost : 0,
        shippingTime: shippingInfo.shippingType === 'otro' ? shippingTime : ''
      });

      localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: {
        shippingType: shippingInfo.shippingType,
        shippingCity: value,
        shippingAddress: shippingInfo.shippingAddress,
        shippingCost: shippingInfo.shippingType === 'otro' ? shippingCost : 0,
        shippingTime: shippingInfo.shippingType === 'otro' ? shippingTime : ''
      }}));
    } else if (name === 'tipo-envio') {
      const newShippingType = value;
      const { shippingCost, shippingTime } = calculateShippingCost(shippingInfo.shippingCity);

      setShippingInfo({
        ...shippingInfo,
        shippingType: newShippingType,
        shippingCost: newShippingType === 'otro' ? shippingCost : 0,
        shippingTime: newShippingType === 'otro' ? shippingTime : ''
      });

      localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: {
        shippingType: newShippingType,
        shippingCity: shippingInfo.shippingCity,
        shippingAddress: shippingInfo.shippingAddress,
        shippingCost: newShippingType === 'otro' ? shippingCost : 0,
        shippingTime: newShippingType === 'otro' ? shippingTime : ''
      }}));
    }

    setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, surname, document, email, mobile, typedocument, companyname, politicas, terminos, ciudad, address } = formData;

    const regexNombre = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/;
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const patronNumeroEcuador = /^\+5939\d{8}$/;
    //const regexAddress = /^(?=.*[a-zA-Z]{3,})(?=.*\d).{4,}$/; // Al menos 3 letras, 1 número y mínimo 4 caracteres en total
    const regexAddress = /^(?=.*[a-zA-Z]{3,}).{4,}$/;

    if (Object.keys(cart).length < 1) newErrors.carrito = 'Carrito vacío';
    if (!regexNombre.test(name)) newErrors.name = 'El nombre no es válido';
    if (!regexNombre.test(surname)) newErrors.surname = 'El apellido no es válido';
    if (!regexAddress.test(address)) newErrors.address = 'La dirección no es válida';
    if (!re.test(email)) newErrors.email = 'El correo electrónico ingresado no es válido';
    if (!patronNumeroEcuador.test(mobile)) newErrors.mobile = 'No es un número válido de Ecuador';

    if (typedocument === 'CI' && document.length !== 10) newErrors.document = 'Cédula debe tener 10 dígitos para CI';
    if (typedocument === 'RUC' && document.length !== 13) newErrors.document = 'Cédula debe tener 13 dígitos para RUC';
    if (typedocument === 'PPN' && (document.length < 4 || document.length > 16)) newErrors.document = 'Pasaporte debe tener entre 4 y 16 dígitos';

    if (typedocument === 'RUC' && !companyname) newErrors.companyname = 'Se requiere razón social.';
    if (!politicas) newErrors.politicas = 'No se aceptaron las políticas de protección de datos.';
    if (!terminos) newErrors.terminos = 'No se aceptaron los términos y condiciones.';

    if (!shippingInfo.shippingCity) newErrors.ciudad = 'No se seleccionó ciudad.';

    return newErrors;
};

  const verificarStockProductos = async () => {
    return { success: true }; // Placeholder
  };

  const total = Object.values(cart).reduce((acc, item) => acc + item.price * item.cant, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEsperando(true);
    //setSold(true);

    const newErrors = validateForm();
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setEsperando(false);
      notify('Por favor corrige los errores en el formulario', 'error');
      return;
    }

    const stockResult = await verificarStockProductos();
    if (!stockResult.success) {
      setEsperando(false);
      notify(stockResult.mensaje, 'error');
      return;
    }

    localStorage.setItem('correoElectronico', formData.email);

    try {
      const headers = {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
      };

      const contactResponse = await axios.get(
        `${process.env.REACT_APP_SERVERURL}/hub/searchContact?email=${formData.email}`,
        { headers }
      );

      let contact;
      if (contactResponse.data.total > 0) {
        contact = contactResponse.data.results[0];

        console.log("El contacto existe. Actualizando datos...");

        const contactId = contactResponse.data.results[0].id;
        const properties = {
          firstname: formData.name,
          lastname: formData.surname,
          email: formData.email,
          city: shippingInfo.shippingCity,
          phone: formData.mobile,
          address: formData.address || shippingInfo.shippingAddress,
          cedula_de_identidad_o_ruc: formData.document,
          canal_de_venta: "Ecommerce"
        }

        console.log(contactId, properties)
        
        const contactUpdateResponse = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/hub/updateContact`,
          {
            contactId,
            properties
          },
          { headers }
        );
        contact = contactUpdateResponse.data;
        




      } else {
        const contactCreateResponse = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/hub/createContact`,
          {
            properties: {
              firstname: formData.name,
              lastname: formData.surname,
              email: formData.email,
              city: shippingInfo.shippingCity,
              phone: formData.mobile,
              address: formData.address || shippingInfo.shippingAddress,
              cedula_de_identidad_o_ruc: formData.document,
              canal_de_venta: "Ecommerce"
            }
          },
          { headers }
        );
        contact = contactCreateResponse.data;
      }

      let searchPenddingDealsResponse;
      
      if (contact) {
        console.log("Contacto:", contact);
        console.log("Buscando negocios pendientes");

        const searchPenddingDeals = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/searchPenddingDeals?contact=${contact.id}`,
          { headers }
        );
        searchPenddingDealsResponse = searchPenddingDeals.data;
        console.log("Negocios pendientes: ", searchPenddingDealsResponse);
      }
      
      if (searchPenddingDealsResponse.total >= 1) {
        console.log("Usted tiene negocios pendientes.");
        notify("Usted tiene negocios pendientes.");
        setPenddingDeals(searchPenddingDealsResponse)
        setEsperando(false);
        return;

      } else {

        let ipAddress = 'N/A';
        try {
          const ipResponse = await axios.get(`${process.env.REACT_APP_SERVERURL}/api/get-ip`);
          ipAddress = ipResponse.data.ip;
        } catch (ipError) {
          notify('No se pudo obtener la IP. Por favor, desactiva el antivirus o las restricciones del navegador y vuelve a intentarlo.', 'error');
          setEsperando(false);
          return;
        }
  
        const referencePurchase = `ECOM-${contact.id}-${new Date().getTime()}`;
        console.log("referencePurchase", referencePurchase);
        console.log("Total que viaja a placetopay", total);
        console.log("Total actualizado", total + shippingInfo.shippingCost);
        const paymentResponse = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/placetopay/procesarPago`,
          {
            buyer: {
              id: contact,
              name: formData.name,
              surname: formData.surname,
              email: formData.email,
              document: formData.document,
              documentType: formData.typedocument,
              mobile: parseInt(formData.mobile),
              city: shippingInfo.shippingCity,
              envio: shippingInfo.shippingType
            },
            ipAddress: ipAddress,
            productos: JSON.stringify(cart),
            resumencompra: JSON.stringify({
              purchaseCart: cart,
              purchaseDate: new Date().toISOString(),
              purchaseShippingInfo: shippingInfo,
              purchaseTotal: total + (shippingInfo.shippingType === 'otro' ? shippingInfo.shippingCost : 0)
            }),
            shippingInfo,
            total: total + shippingInfo.shippingCost,
            reference: referencePurchase
          },
          { headers }
        );
    
        console.log("paymentResponse", paymentResponse);
    
        const { requestId, processUrl, expiration } = paymentResponse.data;
        setRequestId(requestId);
        setUrlForm(processUrl);
        setUrl(processUrl);
        setExpiration(expiration);
    
        localStorage.setItem('ultimaCompra', JSON.stringify(cart));
        localStorage.setItem('paymentUrl', JSON.stringify({ value: processUrl }));
        localStorage.setItem('requestId', requestId.toString());
        localStorage.setItem('expiration', JSON.stringify(expiration));
  
        const createDeal = await axios.post(
          process.env.REACT_APP_SERVERURL + '/hub/createAssosiatedDeal',
          {
            contact,
            canal_de_venta: "Ecommerce",
            ciudad_e_commerce: shippingInfo.shippingCity,
            resumencompra: JSON.stringify({
              purchaseCart: cart,
              purchaseDate: new Date().toISOString(),
              purchaseShippingInfo: shippingInfo,
              purchaseTotal: total + (shippingInfo.shippingType === 'otro' ? shippingInfo.shippingCost : 0),
              reference: referencePurchase
            }),
            total: total + (shippingInfo.shippingType === 'otro' ? shippingInfo.shippingCost : 0),
            address: shippingInfo.shippingAddress,
            requestid: requestId.toString(),
            placetopay_url: processUrl,
            placetopay_reference: referencePurchase
          },
          { headers }
        );
        

        console.log("Creando el negocio de HubSpot:", createDeal.status, createDeal.data.id);
        setDealId(createDeal.data.id);
  
        setEsperando(false);
    
        const openLightbox = () => {
          if (window.PlacetoPay) {
            window.PlacetoPay.open(processUrl, {
              onOpen: () => console.log('Lightbox opened'),
              onClose: () => console.log('Lightbox closed'),
              onFail: (error) => console.error('Lightbox failed', error),
            });
          } else {
            console.error('Placetopay script not loaded');
          }
        };
    
        openLightbox();
      }
    } catch (error) {
      console.error('Error en el procesamiento de pago o creación de contacto/negocio:', error);
      notify('Error en el procesamiento de pago o creación de contacto/negocio', 'error');
      setEsperando(false);
      if (error.code === 'ERR_NETWORK' && error.message.includes('ERR_BLOCKED_BY_CLIENT')) {
        notify('Por favor, intente con Chrome o desactive las restricciones del navegador', 'error');
      } else {
        notify('Error en el procesamiento de pago o creación de contacto/negocio', 'error');
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('correoElectronico');
    localStorage.removeItem('paymentUrl');
    clearCart();
    setUrlForm(null);
    setUrl(null);
  };

  const handleActivity = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleLogout, 600000);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "shippingInfo") {
        const savedShippingInfo = JSON.parse(event.newValue);
        if (savedShippingInfo && JSON.stringify(savedShippingInfo.value) !== JSON.stringify(shippingInfo)) {
          setShippingInfo(savedShippingInfo.value);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [shippingInfo, setShippingInfo]);

  return {
    handleChange,
    handleSubmit,
    calculateShippingCost,
    formData,
    setFormData,
    errors,
    esperando,
    ciudades,
    shippingInfo,
    urlForm,
    expiration,
    requestId,
    penddingDeals
  };
};

export default useFormularioCompra;
