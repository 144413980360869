
/*
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const [formData, setFormData] = useState({
    envio: shippingInfo.shippingType || "",
    ciudad: shippingInfo.shippingCity || "",
    tiempo: shippingInfo.shippingTime || "",
    costo: shippingInfo.shippingCost || 0
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === 'envio') {
      if (value === 'retiro-local') {
        const newShippingInfo = {
          shippingType: value,
          shippingCity: "",
          shippingTime: "",
          shippingCost: 0
        };
        setShippingInfo(newShippingInfo);
        setFormData({
          envio: value,
          ciudad: "",
          tiempo: "",
          costo: 0
        });
        localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
      } else {
        const cityShippingInput = document.querySelector(".resumen-carrito select#ciudad");
        if (formData.ciudad === "") {
          notify('Debe seleccionar una ciudad para el envío.', 'error');
        } else {
          setShippingInfo({ ...shippingInfo, shippingType: value });
        }
      }
    } else if (name === 'ciudad') {
      const dataShippingCalc = ciudades.find(c => c.ciudad === value);
      if (dataShippingCalc) {
        const newShippingInfo = {
          ...shippingInfo,
          shippingCity: value,
          shippingTime: dataShippingCalc.tiempo_de_entrega_horas || "",
          shippingCost: dataShippingCalc.valor || 0
        };
        setShippingInfo(newShippingInfo);
        localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
        setFormData((prevData) => ({
          ...prevData,
          ciudad: value,
          tiempo: dataShippingCalc.tiempo_de_entrega_horas || "",
          costo: dataShippingCalc.valor || 0
        }));
      }
    }
  };
  


  useEffect(() => {
    if (formData.envio !== 'retiro-local') {
      const updatedShippingInfo = {
        shippingType: formData.envio,
        shippingCity: formData.ciudad,
        shippingTime: formData.tiempo,
        shippingCost: formData.costo
      };

      if (JSON.stringify(updatedShippingInfo) !== JSON.stringify(shippingInfo)) {
        setShippingInfo(updatedShippingInfo);
        localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: updatedShippingInfo }));
      }
    }
  }, [formData, shippingInfo, setShippingInfo]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "shippingInfo") {
        const savedShippingInfo = JSON.parse(event.newValue);
        if (savedShippingInfo && JSON.stringify(savedShippingInfo.value) !== JSON.stringify(shippingInfo)) {
          setFormData({
            envio: savedShippingInfo.value.shippingType || "",
            ciudad: savedShippingInfo.value.shippingCity || "",
            tiempo: savedShippingInfo.value.shippingTime || "",
            costo: savedShippingInfo.value.shippingCost || 0
          });
          setShippingInfo(savedShippingInfo.value);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setShippingInfo, shippingInfo]);

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="envio"
            value={formData.envio}
            onChange={handleChange}
          >
            <option value="" disabled>Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {formData.envio === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={formData.ciudad}
                onChange={(e) => {
                  handleChange(e);
                  setShippingInfo({ ...shippingInfo, shippingCity: e.target.value });
                }}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.ciudad}>
                    {ciudad.ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${formData.costo ? formData.costo.toFixed(2) : 0}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{formData.tiempo} hs</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/



/**********************************************/
/**********************************************/
/**********************************************/
/* Sincroniza ciudad, costo y tiempo de envio */
/**********************************************/
/**********************************************/
/**********************************************/



/*
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCity: value === 'retiro-local' ? "" : shippingInfo.shippingCity,
      shippingTime: value === 'retiro-local' ? "" : shippingInfo.shippingTime,
      shippingCost: value === 'retiro-local' ? 0 : shippingInfo.shippingCost,
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const dataShippingCalc = ciudades.find(c => c.Ciudad === value);
    if (dataShippingCalc) {
      const newShippingInfo = {
        ...shippingInfo,
        shippingCity: value,
        shippingTime: dataShippingCalc.tiempo_de_entrega_horas || "",
        shippingCost: dataShippingCalc.valor || 0
      };
      setShippingInfo(newShippingInfo);
      localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
    }
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime} hs</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/





/*
import React, { useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo, cart } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const calculateShippingCost = (ciudad) => {
    const dataShippingCalc = ciudades.find(c => c.Ciudad === ciudad);
    if (!dataShippingCalc) return { cost: 0, time: "" };

    const isMoto = Object.values(cart).some(item => item.category === 'Motos');
    const cost = isMoto ? dataShippingCalc['Costo Motos'] : dataShippingCalc['Costo Accesorios'];
    const time = dataShippingCalc['Tiempo de entrega horas'];

    return { cost, time };
  };

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCity: value === 'retiro-local' ? "" : shippingInfo.shippingCity,
      shippingTime: value === 'retiro-local' ? "" : shippingInfo.shippingTime,
      shippingCost: value === 'retiro-local' ? 0 : shippingInfo.shippingCost,
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const { cost, time } = calculateShippingCost(value);

    const newShippingInfo = {
      ...shippingInfo,
      shippingCity: value,
      shippingTime: time,
      shippingCost: cost
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/




/*
import React, { useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo, cart } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCity: value === 'retiro-local' ? "" : shippingInfo.shippingCity,
      shippingTime: value === 'retiro-local' ? "" : shippingInfo.shippingTime,
      shippingCost: value === 'retiro-local' ? 0 : shippingInfo.shippingCost,
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const dataShippingCalc = ciudades.find(c => c.Ciudad === value);
    if (dataShippingCalc) {
      const isMoto = Object.values(cart).some(item => item.descripcion_familia === 'Motos');
      const shippingCost = isMoto ? dataShippingCalc["Costo Motos"] : dataShippingCalc["Costo Accesorios"];
      const shippingTime = dataShippingCalc["Tiempo de entrega horas"];

      const newShippingInfo = {
        ...shippingInfo,
        shippingCity: value,
        shippingTime,
        shippingCost
      };
      setShippingInfo(newShippingInfo);
      localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
    }
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/



/*
import React, { useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { cart, shippingInfo, setShippingInfo } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const calculateShippingCost = (city) => {
    const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === city);
    if (!selectedCity) return { shippingCost: 0, shippingTime: '' };

    let totalCost = 0;
    for (const item of Object.values(cart)) {
      const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
      totalCost += cost;
    }

    return { shippingCost: totalCost, shippingTime: selectedCity["Tiempo de entrega horas"] };
  };

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCity: value === 'retiro-local' ? "" : shippingInfo.shippingCity,
      shippingTime: value === 'retiro-local' ? "" : shippingInfo.shippingTime,
      shippingCost: value === 'retiro-local' ? 0 : shippingInfo.shippingCost,
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const { shippingCost, shippingTime } = calculateShippingCost(value);

    const newShippingInfo = {
      ...shippingInfo,
      shippingCity: value,
      shippingTime,
      shippingCost
    };

    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/



/*
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCity: value === 'retiro-local' ? "" : shippingInfo.shippingCity,
      shippingTime: value === 'retiro-local' ? "" : shippingInfo.shippingTime,
      shippingCost: value === 'retiro-local' ? 0 : shippingInfo.shippingCost,
    };
    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const dataShippingCalc = ciudades.find(c => c.Ciudad === value);
    if (dataShippingCalc) {
      const newShippingInfo = {
        ...shippingInfo,
        shippingCity: value,
        shippingTime: shippingInfo.shippingType === 'otro' ? dataShippingCalc["Tiempo de entrega horas"] : "",
        shippingCost: shippingInfo.shippingType === 'otro' ? dataShippingCalc["Costo Accesorios"] : 0
      };
      setShippingInfo(newShippingInfo);
      localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
    }
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/







/*
import React, { useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo, cart } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const calculateShippingCost = (city, type) => {
    const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === city);
    if (!selectedCity) return { shippingCost: 0, shippingTime: '' };

    let totalCost = 0;

    if (type === "otro") {
      for (const item of Object.values(cart)) {
        const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
        totalCost += cost;
      }
    } else {
      totalCost = 0;
    }

    return { shippingCost: totalCost, shippingTime: selectedCity["Tiempo de entrega horas"] };
  };

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const { shippingCost, shippingTime } = calculateShippingCost(shippingInfo.shippingCity, value);

    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCost: value === 'otro' ? shippingCost : 0,
      shippingTime: value === 'otro' ? shippingTime : ''
    };

    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const { shippingCost, shippingTime } = calculateShippingCost(value, shippingInfo.shippingType);

    const newShippingInfo = {
      ...shippingInfo,
      shippingCity: value,
      shippingCost: shippingInfo.shippingType === 'otro' ? shippingCost : 0,
      shippingTime: shippingInfo.shippingType === 'otro' ? shippingTime : ''
    };

    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
*/



import React, { useContext } from "react";
import { CartContext } from "../contextos/CartContext";
import listaPrecioEnvio from "../data/listaPrecioEnvio.json";
import { useNotification } from '../contextos/NotificationContext';

const EnvioDetalles = () => {
  const { shippingInfo, setShippingInfo, cart, sold } = useContext(CartContext);
  const { ciudades } = listaPrecioEnvio;
  const { notify } = useNotification();

  const calculateShippingCost = (city, type) => {
    const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === city);
    if (!selectedCity) return { shippingCost: 0, shippingTime: '' };

    let totalCost = 0;

    if (type === "otro") {
      for (const item of Object.values(cart)) {
        const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
        totalCost += cost * item.cant;
      }
    } else {
      totalCost = 0;
    }

    return { shippingCost: totalCost, shippingTime: selectedCity["Tiempo de entrega horas"] };
  };

  const handleShippingTypeChange = (e) => {
    const { value } = e.target;
    const { shippingCost, shippingTime } = calculateShippingCost(shippingInfo.shippingCity, value);

    const newShippingInfo = {
      ...shippingInfo,
      shippingType: value,
      shippingCost: value === 'otro' ? shippingCost : 0,
      shippingTime: value === 'otro' ? shippingTime : ''
    };

    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const { shippingCost, shippingTime } = calculateShippingCost(value, shippingInfo.shippingType);

    const newShippingInfo = {
      ...shippingInfo,
      shippingCity: value,
      shippingCost: shippingInfo.shippingType === 'otro' ? shippingCost : 0,
      shippingTime: shippingInfo.shippingType === 'otro' ? shippingTime : ''
    };

    setShippingInfo(newShippingInfo);
    localStorage.setItem('shippingInfo', JSON.stringify({ timestamp: Date.now(), value: newShippingInfo }));
  };

  return (
    <div className="envio-detalles">
      <fieldset>
        <div className="campo">
          <label htmlFor="tipo-envio">Tipo de envío:</label>
          <select
            id="tipo-envio"
            name="tipo-envio"
            value={shippingInfo.shippingType}
            onChange={handleShippingTypeChange}
            disabled={sold && true}
          >
            <option value="">Selecciona un tipo de envío</option>
            <option value="retiro-local">Retiro en local</option>
            <option value="otro">Entrega a domicilio</option>
          </select>
        </div>
      </fieldset>
      {shippingInfo.shippingType === "otro" && (
        <>
          <fieldset>
            <div className="campo">
              <label htmlFor="ciudad-envio">Ciudad:</label>
              <select
                id="ciudad-envio"
                name="ciudad"
                value={shippingInfo.shippingCity}
                onChange={handleCityChange}
                disabled={sold && true}
              >
                <option value="" disabled>Selecciona una ciudad</option>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad.Ciudad}>
                    {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset className="detalleEnvio">
            <div>
              <label>Valor del envío: <span>${shippingInfo.shippingCost.toFixed(2)}</span></label>
            </div>
            <div>
              <label>Tiempo estimado: <span>{shippingInfo.shippingTime}</span></label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
};

export default EnvioDetalles;
