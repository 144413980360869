import { React, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faUser,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';
import ScrollToTopOnClick from '../functions/ScrollToTopOnClick';


function Footer() {

  const { cart } = useContext(CartContext);

  return (
    <footer>
      <div className="flex_container">
        <div className="span3">
          <span id="hs_cos_wrapper_thunder" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>THUNDER</h3></span>
          <div id="hs_cos_wrapper_thunder_text" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="thunder_text">METALTRONIC S.A. Punto de venta de fábrica directo de la marca THUNDER.</div>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_our" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>NOSOTROS</h3></span>
          <span id="hs_cos_wrapper_left_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu" data-hs-cos-general-type="widget" data-hs-cos-type="simple_menu"><div id="hs_menu_wrapper_left_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="" data-menu-id="" aria-label="Navigation Menu">
            <ul role="menu">
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem" target="_self">Historia</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/catalogo" role="menuitem" target="_self">Catálogo</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem" target="_self">Agencias</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/preguntas-frecuentes" role="menuitem" target="_blank">Preguntas Frecuentes</a></li>

              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/politicadeenvios" role="menuitem" target="_blank">Políticas de envíos</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/garantíamotos" role="menuitem" target="_blank">Garantía Motos</a></li>

              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://landing.thundermotos.com/politicadeprivacidad" role="menuitem" target="_blank">Políticas de Privacidad y Datos Personales</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/terminos-y-condiciones" role="menuitem" target="_blank">Términos y condiciones</a></li>

            </ul>
          </div>
          </span>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_category" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>CATEGORÍAS</h3></span>
          <span id="hs_cos_wrapper_right_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu" data-hs-cos-general-type="widget" data-hs-cos-type="simple_menu"><div id="hs_menu_wrapper_right_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="" data-menu-id="" aria-label="Navigation Menu">
            <ul role="menu">
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a role="menuitem" target="_self">Deportivas</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a role="menuitem" target="_self">Doble Propósito</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a role="menuitem" target="_self">Café Racer</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a role="menuitem" target="_self">Utilitarias</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a role="menuitem" target="_self">Scooter</a></li>
            </ul>
          </div>
          </span>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_contactos" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>CONTACTOS</h3></span>
          <span id="hs_cos_wrapper_contact_info" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
            <ul className="lightgreen">
              <li>Avellaneda N69 y Calle E3<br/>Quito, Ecuador.</li>
              <li>+(593) 96 422 2000<br/>+(593) 02 2480350</li>
              <li>ventas.digitales@thundermotos.com</li>
            </ul>
          </span>
        </div>
      </div>
      <div id="hs_cos_wrapper_copy" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="copy">© 2024 THUNDER MOTOS. DISEÑADO POR PLOY SAS.</div>
    <ScrollToTopOnClick/>
    <a href="https://wa.link/wxdk4u" target="_blank" className="whatsapp">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={54} height={54}><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
    </a>
    </footer>
  );
}

export default Footer;
