/*
import React from 'react';

function FiltrosAccesorios({ filtros, familiasAccesorios, coloresAccesorios, tallasAccesorios, handleFilterChange }) {
  return (
    <div className="filter_container select-filter">
      <select onChange={(e) => handleFilterChange(e, 'accesorios', 'familiaSelect')} value={filtros.familiaSelect}>
        <option value="">Todas las categorías</option>
        {familiasAccesorios && familiasAccesorios.length > 0 ? (
          familiasAccesorios.map((familia) => (
            <option key={familia} value={familia}>
              {familia}
            </option>
          ))
        ) : (
          <option value="">No hay categorías disponibles</option>
        )}
      </select>

      <select onChange={(e) => handleFilterChange(e, 'accesorios', 'colorSelect')} value={filtros.colorSelect}>
        <option value="">Todos los colores</option>
        {coloresAccesorios && coloresAccesorios.length > 0 ? (
          coloresAccesorios.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))
        ) : (
          <option value="">No hay colores disponibles</option>
        )}
      </select>

      <select onChange={(e) => handleFilterChange(e, 'accesorios', 'tallaSelect')} value={filtros.tallaSelect}>
        <option value="">Todas las tallas</option>
        {tallasAccesorios && tallasAccesorios.length > 0 ? (
          tallasAccesorios.map((talla) => (
            <option key={talla} value={talla}>
              {talla}
            </option>
          ))
        ) : (
          <option value="">No hay tallas disponibles</option>
        )}
      </select>
    </div>
  );
}

export default FiltrosAccesorios;
*/




import React from 'react';

function FiltrosAccesorios({ filtros, familiasAccesorios, coloresAccesorios, tallasAccesorios, handleFilterChange, handleCategoryChangeAccesorios }) {
  return (
    <div className="filter_container select-filter">
      <select onChange={(e) => handleCategoryChangeAccesorios(e.target.value)} value={filtros.familiaSelect}>
        <option value="">Todas las categorías</option>
        {familiasAccesorios && familiasAccesorios.length > 0 ? (
          familiasAccesorios.map((familia) => (
            <option key={familia} value={familia}>
              {familia}
            </option>
          ))
        ) : (
          <option value="">No hay categorías disponibles</option>
        )}
      </select>
      <select onChange={(e) => handleFilterChange(e, 'accesorios', 'colorSelect')} value={filtros.colorSelect}>
        <option value="">Todos los colores</option>
        {coloresAccesorios && coloresAccesorios.length > 0 ? (
          coloresAccesorios.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))
        ) : (
          <option value="">No hay colores disponibles</option>
        )}
      </select>
      <select onChange={(e) => handleFilterChange(e, 'accesorios', 'tallaSelect')} value={filtros.tallaSelect}>
        <option value="">Todas las tallas</option>
        {tallasAccesorios && tallasAccesorios.length > 0 ? (
          tallasAccesorios.map((talla) => (
            <option key={talla} value={talla}>
              {talla}
            </option>
          ))
        ) : (
          <option value="">No hay tallas disponibles</option>
        )}
      </select>
    </div>
  );
}

export default FiltrosAccesorios;
