import React from 'react'

function Usuario({userAccount}) {
  return (
    <div className="user">
        <h3>{userAccount.firstname} {userAccount.lastname}</h3>
        <p>{userAccount.email}</p>
    </div>
  )
}

export default Usuario