/*
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Compra from "./Compra";
import { CartContext } from "../contextos/CartContext";
import { AuthContext } from "../contextos/AuthContext";
import Usuario from '../components/Usuario';

function ListaCompras() {
  const { clearCart } = useContext(CartContext);
  const { isLoggedIn, email, esperando, handleLogin, setEsperando, setEmail } = useContext(AuthContext);
  const [compras, setCompras] = useState([]);
  const [dataStatus, setDataStatus] = useState("esperando");
  const [paymentStatus, setPaymentStatus] = useState({});
  const [paymentAuthorization, setPaymentAuthorization] = useState({});
  const [userAccount, setUserAccount] = useState({});

  const requestNegocios = async (Email) => {
    setEsperando(true);
    try {
      const headers = {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
      };

      const searchContactRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/searchContact?email=${Email}`, { headers });
      if (!searchContactRes.data.results || searchContactRes.data.results.length === 0) {
        setDataStatus("noEncontrado");
        setEsperando(false);
        return;
      }

      if (searchContactRes.data.results[0].properties) {
        setUserAccount(searchContactRes.data.results[0].properties);
        console.log(userAccount);
      }

      const contactId = searchContactRes.data.results[0].id;
      const negociosRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/getAssociatedDeals/${contactId}`, { headers });
      const comprasData = negociosRes.data;

      if (comprasData.length > 0) {
        setDataStatus("listo");
      } else {
        setDataStatus("vacio");
      }

      // Ordenar las compras por fecha en orden descendente
      const comprasOrdenadas = comprasData.sort((a, b) => new Date(b.properties.createdate) - new Date(a.properties.createdate));

      setCompras(comprasOrdenadas);

      const paymentStatusPromises = comprasOrdenadas.map(async (compra) => {
        const requestId = compra.properties.requestid;
        if (!requestId) {
          throw new Error("No se encontró requestid en los datos del negocio");
        }

        const paymentRes = await axios.post(`${process.env.REACT_APP_SERVERURL}/placetopay/comprobarPago`, { requestId }, { headers });
        const status = paymentRes.data.status.status;

        // Actualizamos el estado de paymentAuthorization de manera similar a paymentStatus
        setPaymentAuthorization((prevAuth) => ({
          ...prevAuth,
          [requestId]: paymentRes.data
        }));

        let newDealStage = null;
        if (status === "APPROVED" && compra.properties.dealstage !== "checkout_completed") {
          newDealStage = "checkout_completed";
        } else if (status === "REJECTED" && compra.properties.dealstage !== "cancelled") {
          newDealStage = "cancelled";
        }

        if (newDealStage) {
          await axios.post(`${process.env.REACT_APP_SERVERURL}/hub/updateDeal`, {
            dealId: compra.id,
            dealstage: newDealStage,
          }, { headers });

          setCompras((prevCompras) =>
            prevCompras.map((prevCompra) =>
              prevCompra.id === compra.id
                ? { ...prevCompra, properties: { ...prevCompra.properties, dealstage: newDealStage } }
                : prevCompra
            )
          );
        }

        const storedRequestId = localStorage.getItem("requestId");
        if (storedRequestId && storedRequestId === requestId && (newDealStage === "checkout_completed" || newDealStage === "cancelled")) {
          localStorage.removeItem("requestId");
          localStorage.removeItem("paymentUrl");
          localStorage.removeItem("ultimaCompra");
          localStorage.removeItem("shippingInfo");
          localStorage.removeItem("cart");
          clearCart();
        }

        return { requestId, status };
      });

      const paymentStatusResults = await Promise.all(paymentStatusPromises);
      const newPaymentStatus = {};
      paymentStatusResults.forEach(({ requestId, status }) => {
        newPaymentStatus[requestId] = status;
      });
      setPaymentStatus(newPaymentStatus);

    } catch (e) {
      console.error(e.message);
      setDataStatus("noEncontrado");
    } finally {
      setEsperando(false);
    }
  };

  const getContact = async (e) => {
    e.preventDefault();
    await handleLogin(email);
    await requestNegocios(email);
  };

  useEffect(() => {
    if (isLoggedIn) {
      requestNegocios(email);
    }
  }, [isLoggedIn, email]);

  const hasPendingPayment = compras.some((compra) => paymentStatus[compra.properties.requestid] === "PENDING");

  if (esperando) {
    return <div className="account_container">Esperando respuesta...</div>;
  } else {
    return (
      <div className="account_container">
        {isLoggedIn && (
          <Usuario userAccount={userAccount} />
        )}
        <div className="lista-compras">
          {(hasPendingPayment && isLoggedIn) && (
            <div className="alerta-pendiente">
              Tienes una transacción pendiente.
            </div>
          )}
          {!isLoggedIn ? (
            <>
              <p className="titulo">Ingresa tu correo electrónico para ver tus compras.</p>
              <div>
                <form onSubmit={getContact}>
                  <fieldset className="field">
                    <label htmlFor="correo">Email</label>
                    <input name="correo" onChange={(e) => setEmail(e.target.value)} type="email" value={email} required />
                  </fieldset>
                  <div className="action">                    
                    <button
                      disabled={esperando}
                      className={esperando ? "disabled" : "enabled"}
                      type="submit"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              {dataStatus === "noEncontrado" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado el contacto</div>
                </div>
              ) : dataStatus === "vacio" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado compras para el contacto</div>
                </div>
              ) : (
                <div className="lista-compras">
                  {compras.length !== 0 ? (
                    compras.map((compra) => {
                      const { id, properties } = compra;
                      const currentPaymentStatus = paymentStatus[properties.requestid];
                      const currentPaymentAuthorization = paymentAuthorization[properties.requestid];
                      return <Compra key={id} id={id} props={properties} paymentStatus={currentPaymentStatus} paymentAuthorization={currentPaymentAuthorization}/>;
                    })
                  ) : (
                    <div className="no-compras">No hemos encontrado compras</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ListaCompras;
*/



/*
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Compra from "./Compra";
import { CartContext } from "../contextos/CartContext";
import { AuthContext } from "../contextos/AuthContext";
import Usuario from '../components/Usuario';

function ListaCompras() {
  const { clearCart } = useContext(CartContext);
  const { isLoggedIn, email, esperando, handleLogin, setEsperando, setEmail } = useContext(AuthContext);
  const [compras, setCompras] = useState([]);
  const [dataStatus, setDataStatus] = useState("esperando");
  const [paymentStatus, setPaymentStatus] = useState({});
  const [paymentAuthorization, setPaymentAuthorization] = useState({});
  const [userAccount, setUserAccount] = useState({});

  const requestNegocios = async (Email) => {
    setEsperando(true);
    try {
      const headers = {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
      };

      // Buscar contacto en HubSpot
      const searchContactRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/searchContact?email=${Email}`, { headers });
      if (!searchContactRes.data.results || searchContactRes.data.results.length === 0) {
        setDataStatus("noEncontrado");
        setEsperando(false);
        return;
      }

      // Actualizamos el estado del usuario
      if (searchContactRes.data.results[0].properties) {
        setUserAccount(searchContactRes.data.results[0].properties);
        console.log("Datos del usuario obtenidos:", searchContactRes.data.results[0].properties); // Revisar los datos del usuario
      }

      const contactId = searchContactRes.data.results[0].id;
      const negociosRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/getAssociatedDeals/${contactId}`, { headers });
      const comprasData = negociosRes.data;

      if (comprasData.length > 0) {
        setDataStatus("listo");
      } else {
        setDataStatus("vacio");
      }

      // Ordenar las compras por fecha
      const comprasOrdenadas = comprasData.sort((a, b) => new Date(b.properties.createdate) - new Date(a.properties.createdate));
      setCompras(comprasOrdenadas);
      console.log("Compras ordenadas:", comprasOrdenadas); // Log para verificar las compras

      // Revisamos el estado de los pagos para cada compra
      const paymentStatusPromises = comprasOrdenadas.map(async (compra) => {
        const requestId = compra.properties.requestid;
        if (!requestId) {
          throw new Error("No se encontró requestid en los datos del negocio");
        }

        // Llamada a Placetopay para obtener el estado del pago
        const paymentRes = await axios.post(`${process.env.REACT_APP_SERVERURL}/placetopay/comprobarPago`, { requestId }, { headers });
        console.log("Respuesta del estado de pago:", paymentRes.data); // Log para verificar el estado de pago

        const status = paymentRes.data.status.status;

        // Actualizamos el estado de paymentAuthorization
        setPaymentAuthorization((prevAuth) => ({
          ...prevAuth,
          [requestId]: paymentRes.data
        }));

        // Actualización del estado de negocio según el estado de pago
        let newDealStage = null;
        if (status === "APPROVED" && compra.properties.dealstage !== "checkout_completed") {
          newDealStage = "checkout_completed";
        } else if (status === "REJECTED" && compra.properties.dealstage !== "cancelled") {
          newDealStage = "cancelled";
        }

        if (newDealStage) {
          await axios.post(`${process.env.REACT_APP_SERVERURL}/hub/updateDeal`, {
            dealId: compra.id,
            dealstage: newDealStage,
          }, { headers });

          // Actualizar el estado de las compras después de cambiar el dealStage
          setCompras((prevCompras) =>
            prevCompras.map((prevCompra) =>
              prevCompra.id === compra.id
                ? { ...prevCompra, properties: { ...prevCompra.properties, dealstage: newDealStage } }
                : prevCompra
            )
          );
        }

        // Limpiar carrito si es necesario
        const storedRequestId = localStorage.getItem("requestId");
        if (storedRequestId && storedRequestId === requestId && (newDealStage === "checkout_completed" || newDealStage === "cancelled")) {
          localStorage.removeItem("requestId");
          localStorage.removeItem("paymentUrl");
          localStorage.removeItem("ultimaCompra");
          localStorage.removeItem("shippingInfo");
          localStorage.removeItem("cart");
          clearCart();
        }

        return { requestId, status };
      });

      const paymentStatusResults = await Promise.all(paymentStatusPromises);
      const newPaymentStatus = {};
      paymentStatusResults.forEach(({ requestId, status }) => {
        newPaymentStatus[requestId] = status;
      });
      setPaymentStatus(newPaymentStatus);

    } catch (e) {
      console.error("Error en la solicitud de negocios:", e.message);
      setDataStatus("noEncontrado");
    } finally {
      setEsperando(false);
    }
  };

  const getContact = async (e) => {
    e.preventDefault();
    await handleLogin(email);
    await requestNegocios(email);
  };

  useEffect(() => {
    if (isLoggedIn) {
      requestNegocios(email);
    }
  }, [isLoggedIn, email]);

  const hasPendingPayment = compras.some((compra) => paymentStatus[compra.properties.requestid] === "PENDING");

  if (esperando) {
    return <div className="account_container">Esperando respuesta...</div>;
  } else {
    return (
      <div className="account_container">
        {isLoggedIn && (
          <Usuario userAccount={userAccount} />
        )}
        <div className="lista-compras">
          {(hasPendingPayment && isLoggedIn) && (
            <div className="alerta-pendiente">
              Tienes una transacción pendiente.
            </div>
          )}
          {!isLoggedIn ? (
            <>
              <p className="titulo">Ingresa tu correo electrónico para ver tus compras.</p>
              <div>
                <form onSubmit={getContact}>
                  <fieldset className="field">
                    <label htmlFor="correo">Email</label>
                    <input name="correo" onChange={(e) => setEmail(e.target.value)} type="email" value={email} required />
                  </fieldset>
                  <div className="action">                    
                    <button
                      disabled={esperando}
                      className={esperando ? "disabled" : "enabled"}
                      type="submit"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              {dataStatus === "noEncontrado" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado el contacto</div>
                </div>
              ) : dataStatus === "vacio" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado compras para el contacto</div>
                </div>
              ) : (
                <div className="lista-compras">
                  {compras.length !== 0 ? (
                    compras.map((compra) => {
                      const { id, properties } = compra;
                      const currentPaymentStatus = paymentStatus[properties.requestid];
                      const currentPaymentAuthorization = paymentAuthorization[properties.requestid];
                      return <Compra key={id} id={id} props={properties} paymentStatus={currentPaymentStatus} paymentAuthorization={currentPaymentAuthorization}/>;
                    })
                  ) : (
                    <div className="no-compras">No hemos encontrado compras</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ListaCompras;
*/






import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Compra from "./Compra";
import { CartContext } from "../contextos/CartContext";
import { AuthContext } from "../contextos/AuthContext";
import Usuario from '../components/Usuario';

function ListaCompras() {
  const { clearCart } = useContext(CartContext);
  const { isLoggedIn, email, esperando, handleLogin, setEsperando, setEmail } = useContext(AuthContext);
  const [compras, setCompras] = useState([]);
  const [dataStatus, setDataStatus] = useState("esperando"); // Empezamos en "esperando"
  const [paymentStatus, setPaymentStatus] = useState({});
  const [paymentAuthorization, setPaymentAuthorization] = useState({});
  const [userAccount, setUserAccount] = useState({});

  const requestNegocios = async (Email) => {
    setEsperando(true);
    try {
      const headers = {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
      };

      // Buscar contacto en HubSpot
      const searchContactRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/searchContact?email=${Email}`, { headers });
      if (!searchContactRes.data.results || searchContactRes.data.results.length === 0) {
        console.log("No se encontró el contacto.");
        setDataStatus("noEncontrado"); // Cambiamos a "noEncontrado" si no se encuentra el contacto
        setEsperando(false);
        return;
      }

      if (searchContactRes.data.results[0].properties) {
        setUserAccount(searchContactRes.data.results[0].properties);
        console.log("Datos del usuario obtenidos:", searchContactRes.data.results[0].properties);
      }

      const contactId = searchContactRes.data.results[0].id;
      const negociosRes = await axios.get(`${process.env.REACT_APP_SERVERURL}/hub/getAssociatedDeals/${contactId}`, { headers });
      const comprasData = negociosRes.data;

      // Validamos si existen compras y si la respuesta es correcta
      if (!comprasData || !Array.isArray(comprasData) || comprasData.length === 0) {
        console.log("No se encontraron compras, actualizando estado a 'vacio'.");
        setDataStatus("vacio");
      } else {
        console.log("Compras encontradas, actualizando el estado a 'listo'.");
        setDataStatus("listo");

        // Ordenar las compras por fecha en orden descendente
        const comprasOrdenadas = comprasData.sort((a, b) => new Date(b.properties.createdate) - new Date(a.properties.createdate));
        setCompras(comprasOrdenadas);
        console.log("Compras ordenadas:", comprasOrdenadas); // Log para verificar las compras

        // Revisamos el estado de los pagos para cada compra
        const paymentStatusPromises = comprasOrdenadas.map(async (compra) => {
          const requestId = compra.properties.requestid;
          if (!requestId) {
            console.error("No se encontró requestid en los datos del negocio");
            return { requestId: null, status: "UNKNOWN" };
          }
        
          try {
            // Llamada a Placetopay para obtener el estado del pago
            const paymentRes = await axios.post(`${process.env.REACT_APP_SERVERURL}/placetopay/comprobarPago`, { requestId }, { headers });
            
            // Verificar la estructura completa de la respuesta
            console.log("Respuesta completa de Placetopay:", paymentRes.data);
        
            const status = paymentRes.data.status.status;
            const authorization = paymentRes.data.authorization || ""; // Asegurarse de que la autorización se capture correctamente
        
            // Actualizamos el estado de paymentAuthorization
            setPaymentAuthorization((prevAuth) => ({
              ...prevAuth,
              [requestId]: {
                status,
                authorization, // Pasamos la autorización aquí
              },
            }));
        
            return { requestId, status };
          } catch (error) {
            console.error(`Error al comprobar el pago para requestId ${requestId}:`, error);
            return { requestId, status: "ERROR" };
          }
        });
        

        const paymentStatusResults = await Promise.all(paymentStatusPromises);
        const newPaymentStatus = {};
        paymentStatusResults.forEach(({ requestId, status }) => {
          if (requestId) {
            newPaymentStatus[requestId] = status;
          }
        });
        setPaymentStatus(newPaymentStatus);
      }
    } catch (e) {
      console.error("Error en la solicitud de negocios:", e.message);
      setDataStatus("noEncontrado"); // Cambiar a "noEncontrado" en caso de error
    } finally {
      setEsperando(false);
    }
  };

  const getContact = async (e) => {
    e.preventDefault();
    await handleLogin(email);
    await requestNegocios(email);
  };

  useEffect(() => {
    if (isLoggedIn) {
      requestNegocios(email);
    }
  }, [isLoggedIn, email]);

  const hasPendingPayment = compras.some((compra) => paymentStatus[compra.properties.requestid] === "PENDING");

  if (esperando) {
    return <div className="account_container">Esperando respuesta...</div>;
  } else {
    return (
      <div className="account_container">
        {isLoggedIn && (
          <Usuario userAccount={userAccount} />
        )}
        <div className="lista-compras">
          {(hasPendingPayment && isLoggedIn) && (
            <div className="alerta-pendiente">
              Tienes una transacción pendiente.
            </div>
          )}
          {!isLoggedIn ? (
            <>
              <p className="titulo">Ingresa tu correo electrónico para ver tus compras.</p>
              <div>
                <form onSubmit={getContact}>
                  <fieldset className="field">
                    <label htmlFor="correo">Email</label>
                    <input name="correo" onChange={(e) => setEmail(e.target.value)} type="email" value={email} required />
                  </fieldset>
                  <div className="action">                    
                    <button
                      disabled={esperando}
                      className={esperando ? "disabled" : "enabled"}
                      type="submit"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              {dataStatus === "noEncontrado" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado el contacto</div>
                </div>
              ) : dataStatus === "vacio" ? (
                <div className="lista-compras">
                  <div className="no-compras">No hemos encontrado compras para el contacto</div>
                </div>
              ) : (
                <div className="lista-compras">
                  {compras.length !== 0 ? (
                    compras.map((compra) => {
                      const { id, properties } = compra;
                      const currentPaymentStatus = paymentStatus[properties.requestid];
                      const currentPaymentAuthorization = paymentAuthorization[properties.requestid];
                      return <Compra key={id} id={id} props={properties} paymentStatus={currentPaymentStatus} paymentAuthorization={currentPaymentAuthorization}/>;
                    })
                  ) : (
                    <div className="no-compras">No hemos encontrado compras</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ListaCompras;