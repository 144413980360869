import React, { useEffect, useContext, useState } from 'react';
import useFormularioCompra from './useFormularioCompra';
import { CartContext } from '../../contextos/CartContext';

const FormularioCompra = () => {
  const {
    handleChange,
    handleSubmit,
    calculateShippingCost,
    formData,
    setFormData,  // Necesitarás agregar esta función en useFormularioCompra para manejar el estado del formData
    errors,
    esperando,
    ciudades,
    shippingInfo,
    urlForm,
    expiration,
    penddingDeals
  } = useFormularioCompra();

  const { setUrl, setCart, setShippingInfo, setSold } = useContext(CartContext);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (penddingDeals && penddingDeals.total >= 1) {
      setSold(true);
    }
  }, [penddingDeals]);

  useEffect(() => {
    if (expiration) {
      const expirationDate = new Date(expiration);
      const interval = setInterval(() => {
        const now = new Date();
        const timeDifference = expirationDate - now;

        if (timeDifference <= 0) {
          clearInterval(interval);
          setTimeLeft("00:00:00");
        } else {
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setTimeLeft(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [expiration]);

  useEffect(() => {
    if (window.P) {
      const handleResponse = (response) => {
        console.log(response);
        localStorage.removeItem('paymentUrl');
        localStorage.removeItem('requestId');
        localStorage.removeItem('ultimaCompra');
        setUrl(null);
        setCart({});
        setShippingInfo({ shippingType: "", shippingCity: "", shippingAddress: "", shippingTime: "", shippingCost: 0 });
        //setSold(false);
      };

      window.P.on('close', (response) => {
        console.log('El usuario cerró el Lightbox');
        console.log(response);
        //setSold(true);
      });

      window.P.on('response', (response) => {
        console.log('Respuesta del Lightbox');
        console.log(response);

        if (response.status.status === 'REJECTED') {
          console.log("El pago fue rechazado.");
          console.log("Redireccionando a Mis Compras...");
          localStorage.removeItem('paymentUrl');
          localStorage.removeItem('requestId');
          localStorage.removeItem('ultimaCompra');
          setUrl(null);
          setCart({});
          setShippingInfo({ shippingType: "", shippingCity: "", shippingAddress: "", shippingTime: "", shippingCost: 0 });
          //setSold(true);
          setTimeout(() => {
            window.location.href = '/miscompras';
          }, 1500);

        } else if (response.status.status === 'PENDING') {
          console.log("El pago está pendiente.");
          console.log("Redireccionando a Mis Compras...");
          setCart({});
          //setSold(true);
          setTimeout(() => {
            window.location.href = '/miscompras';
          }, 1500);

        } else if (response.status.status === 'APPROVED') {
          console.log("El pago fue aprobado.");
          console.log("Redireccionando a Mis Compras...");
          localStorage.removeItem('paymentUrl');
          localStorage.removeItem('requestId');
          localStorage.removeItem('ultimaCompra');
          setUrl(null);
          setCart({});
          setShippingInfo({ shippingType: "", shippingCity: "", shippingAddress: "", shippingTime: "", shippingCost: 0 });
          //setSold(false);
          setTimeout(() => {
            window.location.href = '/miscompras';
          }, 1500);
        }
      });
    } else {
      console.error('Placetopay script not loaded');
    }
  }, [setUrl, setCart, setShippingInfo, setSold]);

  useEffect(() => {
    // Inicializar el valor del teléfono con el prefijo +593 si no está presente
    if (!formData.mobile.startsWith("+593")) {
      setFormData({
        ...formData,
        mobile: "+593"
      });
    }
  }, [formData.mobile, setFormData]);

  const handlePhoneChange = (e) => {
    let value = e.target.value;
    
    // Asegurar que el prefijo +593 esté siempre presente
    if (!value.startsWith("+593")) {
      value = "+593" + value.replace(/^(\+593)/, '');
    }
    
    setFormData({
      ...formData,
      mobile: value
    });
  };

  const handleLightbox = () => {
    if (window.P) {
      window.P.init(urlForm);
      //setSold(true);
    } else {
      console.error('Placetopay script not loaded');
    }
  };

  if (penddingDeals && penddingDeals.total >= 1) {
    return (
      <div className="final_checkout">
        <h3>Transacción pendiente</h3>
        <p>Por favor, revise y/o continúe con sus transacciones pendientes.</p>
        <a href="/miscompras" className="btn naranja">Continuar</a>
      </div>
    );
  }

  if (urlForm) {
    return (
      <>
        {timeLeft && (
          <div className="alert-expiration-time">
            <p>Tiempo restante para completar la compra: {timeLeft}</p>
          </div>
        )}
        <div className="final_checkout">
          <h3>Transacción pendiente</h3>
          <p>Haz click en el botón de abajo para completar tu compra.</p>
          <button onClick={handleLightbox} className="btn naranja">
            Finalizar pago
          </button>
        </div>
      </>
    );
  }

  return (
    <>
    <p>*Ten presente que los datos ingresados serán utilizados para el proceso de facturación y, en caso de comprar una moto, para su matriculación. Por favor, asegúrate que los datos sean precisos y completos para evitar demoras.</p>
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div>
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="* Nombres Completos"
          />
          {errors.name && <p className='error'>{errors.name}</p>}
        </div>
        <div>
          <label htmlFor="surname">Apellido:</label>
          <input
            type="text"
            id="surname"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            placeholder="* Apellidos Completos"
          />
          {errors.surname && <p className='error'>{errors.surname}</p>}
        </div>
      </fieldset>
      <fieldset>
        <div>
          <label htmlFor="typedocument">Tipo de Documento:</label>
          <select
            id="typedocument"
            name="typedocument"
            value={formData.typedocument}
            onChange={handleChange}
            placeholder="* Tipo de documento"
          >
            <option disabled>* Tipo de documento</option>
            <option value="CI">CI</option>
            <option value="RUC">RUC</option>
            <option value="PPN">Pasaporte</option>
          </select>
          {errors.typedocument && <p className='error'>{errors.typedocument}</p>}
        </div>
        <div>
          <label htmlFor="document">Documento:</label>
          <input
            type="text"
            id="document"
            name="document"
            value={formData.document}
            onChange={handleChange}
            placeholder="* Documento"
          />
          {errors.document && <p className='error'>{errors.document}</p>}
        </div>
      </fieldset>
      <fieldset>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="* Email"
          />
          {errors.email && <p className='error'>{errors.email}</p>}
        </div>
        <div>
          <label htmlFor="mobile">Teléfono:</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handlePhoneChange}
          />
          {errors.mobile && <p className='error'>{errors.mobile}</p>}
        </div>
      </fieldset>
      <fieldset className='fieldset_one'>
        <div>
          <label htmlFor="ciudad">Ciudad:</label>
          <select
            id="ciudad"
            name="ciudad"
            value={shippingInfo.shippingCity}
            onChange={(e) => {
              handleChange(e);
              const { shippingCost, shippingTime } = calculateShippingCost(e.target.value);
              setShippingInfo({
                ...shippingInfo,
                shippingCity: e.target.value,
                shippingCost,
                shippingTime
              });
            }}
            placeholder="* Ciudad"
          >
            <option value="">* Selecciona una ciudad</option>
            {ciudades.map((ciudad, index) => (
              <option key={index} value={ciudad.Ciudad}>
                {ciudad.Ciudad.replace(/_/g, ' ').split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ')}
              </option>
            ))}
          </select>
          {errors.ciudad && <p className='error'>{errors.ciudad}</p>}
        </div>
      </fieldset>
      <fieldset className='fieldset_one'>
        <div>
          <label htmlFor="address">Dirección:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address} // Cambiar de `formData.direccion` a `formData.address`
            onChange={(e) => {
              handleChange(e);
              setShippingInfo({
                ...shippingInfo,
                shippingAddress: e.target.value // Actualiza `shippingAddress` con el valor del input
              });
            }}
            placeholder="* Dirección"
          />
          {errors.address && <p className='error'>{errors.address}</p>}
        </div>
      </fieldset>
      <fieldset className="cont-terminos">
        <div className="aceptar-terminos">
          <input
            className="checkbox"
            type="checkbox"
            name="terminos"
            checked={formData.terminos || false} // Manejar controlado
            onChange={handleChange}
            disabled={esperando}
          />
          <label>Si acepto los términos y condiciones.</label>
          <p>Puedes conocer nuestros términos y condiciones en este link: <a href='https://www.thundermotos.com/terminos-y-condiciones' target='_blank'>Términos y condiciones</a></p>
        </div>
        {errors.terminos && <p className="error">{errors.terminos}</p>}
      </fieldset>
      <fieldset className="cont-politicas">
        <div className="aceptar-politicas">
          <input
            className="checkbox"
            type="checkbox"
            name="politicas"
            checked={formData.politicas || false} // Manejar controlado
            onChange={handleChange}
            disabled={esperando}
          />
          <label>Si acepto las políticas de protección de datos.</label>
          <p>Puedes conocer nuestras políticas de protección de datos en este link: <a href='https://landing.thundermotos.com/politicadeprivacidad' target='_blank'>Protección de Datos</a></p>
        </div>
        {errors.politicas && <p className="error">{errors.politicas}</p>}
      </fieldset>
      <fieldset>
        <button className="btn verde send" type="submit" disabled={esperando}>
          {esperando ? "Procesando..." : "Enviar"}
        </button>
      </fieldset>
    </form>
    </>
  );
};

export default FormularioCompra;
