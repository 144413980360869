/*
import React, { createContext, useState, useEffect } from "react";
import JsonCiudades from '../data/listaPrecioEnvio.json';

export const CartContext = createContext();

const EXPIRATION_TIME = 30 * 60 * 1000; // Expiración del Carrito
const ciudades = JsonCiudades.ciudades || [];

const checkExpiration = (key) => {
  try {
    const savedData = JSON.parse(localStorage.getItem(key));
    if (savedData) {
      const { timestamp } = savedData;
      if (Date.now() - timestamp > EXPIRATION_TIME) {
        localStorage.removeItem(key);
        return null;
      }
      return savedData.value;
    }
  } catch (error) {
    console.error(`Error parsing JSON from localStorage for key "${key}":`, error);
    localStorage.removeItem(key); // Elimina el valor incorrecto
  }
  return null;
};

export const CartProvider = ({ children }) => {
  const [sold, setSold] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = checkExpiration("cart");
    return savedCart ? savedCart : {};
  });

  const [url, setUrl] = useState(() => {
    const savedUrl = checkExpiration("paymentUrl");
    return savedUrl ? savedUrl : null;
  });

  const [expiration, setExpiration] = useState(() => {
    const savedExpiration = checkExpiration("expiration");
    return savedExpiration ? savedExpiration : null;
  });

  const [shippingInfo, setShippingInfo] = useState(() => {
    const savedShippingInfo = checkExpiration("shippingInfo");
    return savedShippingInfo ? savedShippingInfo : { shippingType: "", shippingCity: "", shippingAddress: "", shippingTime: "", shippingCost: 0 };
  });

  // Function to update localStorage without causing loops
  const updateLocalStorage = (key, value) => {
    const currentData = checkExpiration(key);
    if (JSON.stringify(currentData) !== JSON.stringify(value)) {
      localStorage.setItem(key, JSON.stringify({ timestamp: Date.now(), value }));
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "cart") {
        const newCart = checkExpiration("cart");
        if (newCart && JSON.stringify(newCart) !== JSON.stringify(cart)) {
          setCart(newCart);
        }
      } else if (event.key === "paymentUrl") {
        const newUrl = checkExpiration("paymentUrl");
        if (newUrl && JSON.stringify(newUrl) !== JSON.stringify(url)) {
          setUrl(newUrl);
        }
      } else if (event.key === "expiration") {
        const newExpiration = checkExpiration("expiration");
        if (newExpiration && JSON.stringify(newExpiration) !== JSON.stringify(expiration)) {
          setExpiration(newExpiration);
        }
      } else if (event.key === "shippingInfo") {
        const newShippingInfo = checkExpiration("shippingInfo");
        if (newShippingInfo && JSON.stringify(newShippingInfo) !== JSON.stringify(shippingInfo)) {
          setShippingInfo(newShippingInfo);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [cart, url, expiration, shippingInfo]);

  useEffect(() => {
    updateLocalStorage("cart", cart);
  }, [cart]);

  useEffect(() => {
    updateLocalStorage("shippingInfo", shippingInfo);
  }, [shippingInfo]);

  useEffect(() => {
    if (url) {
      updateLocalStorage("paymentUrl", url);
      setSold(true);
    }
  }, [url]);

  useEffect(() => {
    if (expiration) {
      updateLocalStorage("expiration", expiration);
    }
  }, [expiration]);
 
  // Función para recalcular el costo de envío
  const recalculateShippingCost = () => {
    if (shippingInfo.shippingType === "otro" && shippingInfo.shippingCity) {
      const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === shippingInfo.shippingCity);
      if (selectedCity) {
        let totalCost = 0;
        for (const item of Object.values(cart)) {
          const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
          totalCost += cost * item.cant;
        }
        const updatedShippingInfo = {
          ...shippingInfo,
          shippingCost: totalCost,
          shippingTime: selectedCity["Tiempo de entrega horas"]
        };
        setShippingInfo(updatedShippingInfo);
        updateLocalStorage('shippingInfo', updatedShippingInfo);
      }
    }
  };

  useEffect(() => {
    recalculateShippingCost(); // Recalcular el costo de envío cuando cambie el carrito
  }, [cart]);

  const addToCart = (product) => {
    console.log(product);
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[product.sku]) {
        updatedCart[product.sku].cant += 1;
      } else {
        updatedCart[product.sku] = { ...product, cant: 1 };
      }
      return updatedCart;
    });
  };

  const updateProductQuantity = (sku, newQuantity) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[sku]) {
        updatedCart[sku].cant = newQuantity;
      }
      return updatedCart;
    });
  };
  
  const removeFromCart = (sku) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      delete updatedCart[sku];
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart({});
    localStorage.removeItem("cart");
  };

  const saveLastPurchase = () => {
    const lastPurchase = { cart, shippingInfo };
    localStorage.setItem("ultimaCompra", JSON.stringify({ value: lastPurchase, timestamp: Date.now() }));
  };

  const handlePaymentCancellation = () => {
    localStorage.removeItem("paymentUrl");
    localStorage.removeItem("expiration");
    localStorage.removeItem("requestId");
    localStorage.removeItem("ultimaCompra");
    setUrl(null);
    setExpiration(null);
    setSold(false);
    clearCart();  // Limpiar el carrito si es necesario, ajustar según sea necesario
  };

  const calculateTotal = () => {
    const subtotal = Object.values(cart).reduce((acc, item) => acc + item.price * item.cant, 0);
    const shippingCost = shippingInfo.shippingType === "domicilio" ? shippingInfo.shippingCost : 0;
    return subtotal + shippingCost;
  };

  return (
    <CartContext.Provider value={{
      cart,
      setCart,
      shippingInfo,
      setShippingInfo,
      addToCart,
      updateProductQuantity,
      removeFromCart,
      clearCart,
      saveLastPurchase,
      sold,
      setSold,
      url,
      setUrl,
      expiration,
      setExpiration,
      handlePaymentCancellation,
      calculateTotal,
      recalculateShippingCost // Asegúrate de exportar esta función
    }}>
      {children}
    </CartContext.Provider>
  );
};

*/



/******************************************************************************/
          /* Verifica disponibilidad antes de acgregar al carrito */
/******************************************************************************/


import React, { createContext, useState, useEffect } from "react";
import JsonCiudades from '../data/listaPrecioEnvio.json';
import { useNotification } from './NotificationContext'; // Asegúrate de importar el sistema de notificación

export const CartContext = createContext();

const EXPIRATION_TIME = 30 * 60 * 1000; // Expiración del Carrito
const ciudades = JsonCiudades.ciudades || [];

const checkExpiration = (key) => {
  try {
    const savedData = JSON.parse(localStorage.getItem(key));
    if (savedData) {
      const { timestamp } = savedData;
      if (Date.now() - timestamp > EXPIRATION_TIME) {
        localStorage.removeItem(key);
        return null;
      }
      return savedData.value;
    }
  } catch (error) {
    console.error(`Error parsing JSON from localStorage for key "${key}":`, error);
    localStorage.removeItem(key); // Elimina el valor incorrecto
  }
  return null;
};

export const CartProvider = ({ children }) => {
  const { notify } = useNotification(); // Para notificaciones de stock
  const [sold, setSold] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = checkExpiration("cart");
    return savedCart ? savedCart : {};
  });

  const [url, setUrl] = useState(() => {
    const savedUrl = checkExpiration("paymentUrl");
    return savedUrl ? savedUrl : null;
  });

  const [expiration, setExpiration] = useState(() => {
    const savedExpiration = checkExpiration("expiration");
    return savedExpiration ? savedExpiration : null;
  });

  const [shippingInfo, setShippingInfo] = useState(() => {
    const savedShippingInfo = checkExpiration("shippingInfo");
    return savedShippingInfo ? savedShippingInfo : { shippingType: "", shippingCity: "", shippingAddress: "", shippingTime: "", shippingCost: 0 };
  });

  // Function to update localStorage without causing loops
  const updateLocalStorage = (key, value) => {
    const currentData = checkExpiration(key);
    if (JSON.stringify(currentData) !== JSON.stringify(value)) {
      localStorage.setItem(key, JSON.stringify({ timestamp: Date.now(), value }));
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "cart") {
        const newCart = checkExpiration("cart");
        if (newCart && JSON.stringify(newCart) !== JSON.stringify(cart)) {
          setCart(newCart);
        }
      } else if (event.key === "paymentUrl") {
        const newUrl = checkExpiration("paymentUrl");
        if (newUrl && JSON.stringify(newUrl) !== JSON.stringify(url)) {
          setUrl(newUrl);
        }
      } else if (event.key === "expiration") {
        const newExpiration = checkExpiration("expiration");
        if (newExpiration && JSON.stringify(newExpiration) !== JSON.stringify(expiration)) {
          setExpiration(newExpiration);
        }
      } else if (event.key === "shippingInfo") {
        const newShippingInfo = checkExpiration("shippingInfo");
        if (newShippingInfo && JSON.stringify(newShippingInfo) !== JSON.stringify(shippingInfo)) {
          setShippingInfo(newShippingInfo);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [cart, url, expiration, shippingInfo]);

  useEffect(() => {
    updateLocalStorage("cart", cart);
  }, [cart]);

  useEffect(() => {
    updateLocalStorage("shippingInfo", shippingInfo);
  }, [shippingInfo]);

  useEffect(() => {
    if (url) {
      updateLocalStorage("paymentUrl", url);
      setSold(true);
    }
  }, [url]);

  useEffect(() => {
    if (expiration) {
      updateLocalStorage("expiration", expiration);
    }
  }, [expiration]);

  // Función para recalcular el costo de envío
  const recalculateShippingCost = () => {
    if (shippingInfo.shippingType === "otro" && shippingInfo.shippingCity) {
      const selectedCity = ciudades.find(ciudad => ciudad.Ciudad === shippingInfo.shippingCity);
      if (selectedCity) {
        let totalCost = 0;
        for (const item of Object.values(cart).filter(product => product.stock > 0)) { // Filtra productos sin stock
          const cost = item.descripcion_familia === 'MOTOS ENSAMBLADAS' ? selectedCity["Costo Motos"] : selectedCity["Costo Accesorios"];
          totalCost += cost * item.cant;
        }
        const updatedShippingInfo = {
          ...shippingInfo,
          shippingCost: totalCost,
          shippingTime: selectedCity["Tiempo de entrega horas"]
        };
        setShippingInfo(updatedShippingInfo);
        updateLocalStorage('shippingInfo', updatedShippingInfo);
      }
    }
  };

  useEffect(() => {
    recalculateShippingCost(); // Recalcular el costo de envío cuando cambie el carrito
  }, [cart]);


  const addToCart = (product) => {
    // Verificar que el producto tenga stock mayor a cero
    if (product.stock <= 0) {
      notify(`El producto ${product.name} está fuera de stock`);
      return;
    }
  
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      const existingProduct = updatedCart[product.sku];
  
      console.log(product);

      if (existingProduct) {
        // Si el producto ya está en el carrito, verifica si la cantidad actual más la nueva excede el stock
        if (existingProduct.cant + 1 > product.stock) {
          notify(`No se puede agregar más del producto ${product.name}, solo quedan ${product.stock} en stock.`);
          console.log(existingProduct.cant, product.stock);
        } else {
          // Si la cantidad no excede el stock, incrementa la cantidad
          updatedCart[product.sku].cant += 1;
          //notify(`Producto agregado al carrito: ${product.name}`);
          console.log(existingProduct.cant, product.stock);
        }
      } else {
        // Si el producto no está en el carrito, añádelo con cantidad inicial de 1
        updatedCart[product.sku] = { ...product, cant: 1 };
        //notify(`Agregado al carrito: ${product.name}`);
        console.log(product.stock);
      }
  
      return updatedCart;
    });
  };
  
  

  const updateProductQuantity = (sku, newQuantity) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      const product = updatedCart[sku];
  
      if (product) {
        if (newQuantity > product.stock) {
          notify(`No puedes superar el stock disponible. Solo hay ${product.stock} unidades de ${product.name}.`);
        } else {
          product.cant = newQuantity;
        }
      }
  
      return updatedCart;
    });
  };
  
  
  const removeFromCart = (sku) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      delete updatedCart[sku];
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart({});
    localStorage.removeItem("cart");
  };

  const saveLastPurchase = () => {
    const lastPurchase = { cart, shippingInfo };
    localStorage.setItem("ultimaCompra", JSON.stringify({ value: lastPurchase, timestamp: Date.now() }));
  };

  const handlePaymentCancellation = () => {
    localStorage.removeItem("paymentUrl");
    localStorage.removeItem("expiration");
    localStorage.removeItem("requestId");
    localStorage.removeItem("ultimaCompra");
    setUrl(null);
    setExpiration(null);
    setSold(false);
    clearCart();  // Limpiar el carrito si es necesario, ajustar según sea necesario
  };

  const calculateTotal = () => {
    const subtotal = Object.values(cart).reduce((acc, item) => acc + item.price * item.cant, 0);
    const shippingCost = shippingInfo.shippingType === "domicilio" ? shippingInfo.shippingCost : 0;
    return subtotal + shippingCost;
  };

  return (
    <CartContext.Provider value={{
      cart,
      setCart,
      shippingInfo,
      setShippingInfo,
      addToCart,
      updateProductQuantity,
      removeFromCart,
      clearCart,
      saveLastPurchase,
      sold,
      setSold,
      url,
      setUrl,
      expiration,
      setExpiration,
      handlePaymentCancellation,
      calculateTotal,
      recalculateShippingCost
    }}>
      {children}
    </CartContext.Provider>
  );
};
