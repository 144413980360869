
import React, { useContext } from 'react';
import { ProductsContext } from "../contextos/ProductsContext";

const Producto = (props) => {
  const { stockLimit } = useContext(ProductsContext);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": props.nombre,
    "image": props.img !== "Not Image" && props.img !== null ? props.img : "",
    "description": props.tipo,
    "sku": props.sku || "N/A",
    "offers": {
      "@type": "Offer",
      "url": window.location.href,
      "priceCurrency": "USD",
      "price": props.precio,
      "availability": props.isAvailable ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
      "itemCondition": "https://schema.org/NewCondition",
      "seller": {
        "@type": "Organization",
        "name": "Tienda Thunder Motos"
      }
    }
  };

  return (
    <>
        {props.etiqueta && <h5 className={"etiqueta " + props.etiqueta}>{props.etiqueta}</h5>}
        <img className={props.img ? "image" : "image notImage"} src={props.img || "./NotImage.jpg"} alt={props.nombre} width="340" height="200" loading="lazy"/>
        <h4 className="tipo" value={props.tipo}>{props.tipo}</h4>
        <h3 className="nombre">{props.nombre}</h3>
        <p>{props.colores}</p>
        <p className="precio cantidad">
          {props.isAvailable && <span>{formatCurrency(props.precio)}</span>}
          <span>{props.isAvailable && props.stock}</span>
        </p>
        {props.isAvailable ? (
            <button className="buy-now" type="button" onClick={props.handleClick}>VER MÁS</button>
          ) : (
            <span className='agotado'>AGOTADO</span>
        )}
        {/* Script JSON-LD para Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
    </>
  );
};

export default Producto;