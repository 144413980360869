import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../contextos/CartContext";
import ProductoCarrito from "./ProductoCarrito";
import FormularioCompra from "./FormularioCompra/FormularioCompra";
import ResumenCarrito from "./ResumenCarrito";

const Carrito = () => {
  const { cart, shippingInfo, setShippingInfo } = useContext(CartContext);

  const productos = Object.values(cart);

  const updateShippingInfo = (cost, details) => {
    setShippingInfo({ cost, details });
  };

  return (
    <div className={productos.length === 0 ? "cart_detail_container empty" : "cart_detail_container" }>
      <div className="cont-carrito">
        <div className="cart_products">
          <div className="cart_products_list">
          {productos.length > 0 ? (
            productos.map((producto) => (
              <ProductoCarrito key={producto.id} producto={producto} cant={productos.length}/>
            ))
          ) : (
            <>
              <h3>Tu carrito está vacío.</h3>
              <p>Agrega tu próxima moto al carrito y compra ahora!!!</p>
              <a href="/" className="btn verde inline">IR A LA TIENDA</a>
            </>
          )}
          </div>
          {shippingInfo && productos.length > 0 ? <FormularioCompra shippingInfo={shippingInfo} /> : null}
        </div>
        {productos.length > 0 && <ResumenCarrito updateShippingInfo={updateShippingInfo} />}
      </div>
    </div>
  );
};

export default Carrito;

