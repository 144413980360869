import React,{useState, useEffect, useRef} from "react";
import DetalleProducto from "../components/DetalleProducto";
import Detalles from "../components/Detalles";

function DetallesProducto() {


  return (
    <div className="cont-body">
      {/*
      <DetalleProducto />
       */}
      <Detalles/>
    </div>
  );
}

export default DetallesProducto;
