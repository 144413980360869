import React from 'react'
import ListaCompras from '../components/ListaCompras'

function MisCompras() {
  return (
    <div className="mis-compras">
        <ListaCompras/>
    </div>
  )
}

export default MisCompras