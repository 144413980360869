/*
import React from 'react';

const ProductoPlaceholder = () => {
  return (
    <>
      <div className="image-placeholder skeleton" style={{ width: '340px', height: '200px' }}></div>
      <h4 className="tipo skeleton" style={{ width: '60%', height: '20px' }}></h4>
      <h3 className="nombre skeleton" style={{ width: '80%', height: '20px' }}></h3>
      <p className="colores-placeholder skeleton" style={{ width: '50%', height: '15px' }}></p>
      <p className="precio cantidad skeleton" style={{ width: '50%', height: '20px' }}></p>
      <div className="buy-now-placeholder skeleton" style={{ width: '100px', height: '40px' }}></div>
    </>
  );
};

export default ProductoPlaceholder;
*/


import React from 'react';

const ProductoPlaceholder = () => {
  return (
    <div className="item_product placeholder">
      <div className="image-placeholder animate"></div> {/* Placeholder para la imagen */}
      <h4 className="tipo-placeholder animate"></h4> {/* Placeholder para la categoría */}
      <h3 className="nombre-placeholder animate"></h3> {/* Placeholder para el nombre */}
      <p className="precio cantidad">
        <span className="precio-placeholder animate"></span> {/* Placeholder para el precio */}
        <span className="stock-placeholder animate"></span> {/* Placeholder para el stock */}
      </p>
      <button className="buy-now-placeholder animate" type="button"></button> {/* Placeholder para el botón */}
    </div>
  );
};

export default ProductoPlaceholder;
