import React from 'react'
import InfoPago from '../components/InfoPago'
import Carrito from '../components/Carrito'

import { ProductsProvider } from "../contextos/ProductsContext";


function CarritoPage() {
  return (
    <ProductsProvider>
    <div className="cont-body">
      <div className='cart-container'>
        <Carrito/>
      </div>
      <InfoPago/>
    </div>
    </ProductsProvider>
  )
}

export default CarritoPage